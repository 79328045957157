import React, { useEffect, useState } from "react";
import { CustomNavbar } from "../../../component/Navbar/CustomNavbar";
import { BottomNav } from "../../../component/Navbar/BottomNav";
import { useNavigate } from "react-router-dom";
import {
  AboutUsGet,
  AboutUsUpdate,
  admintoken,
  CheckToken,
} from "../../../component/Api/api";
import { useDispatch, useSelector } from "react-redux";
import { RTE_Readonly } from "../../../component/Rich text editor/RTE_Readonly";
import { get_editor_child, RTE } from "../../../component/Rich text editor/RTE";
import { scrollToTop } from "../../../utils/utils";

export const TermsAndCondition = ({ setloading }) => {
  const current_store = useSelector((state) => state.checktoken_data);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [LoadPage, setLoadPage] = useState(false);
  const [PrivacyList, setPrivacyList] = useState([]);
  const [msg, setmsg] = useState("");
  const read_only = false;
  const [WholeMsg, setWholeMsg] = useState([]);
  const [Edit, setEdit] = useState(false);
  useEffect(() => {
    scrollToTop();
    setloading(true);
    if (admintoken || current_store?.token) {
      CheckToken(admintoken || current_store?.token, ({ status, response }) => {
        if (response.status) {
          if (response?.user?.type) {
            dispatch({
              type: "setCheckToken",
              payload: {
                token: admintoken || current_store?.token,
                userdetail: response.user,
                user_type: response?.user?.type,
              },
            });
          } else {
            nav("/welcome");
          }
        } else {
          setloading(false);
          nav("/admin");
        }
      });
    } else {
      setloading(false);
      nav("/admin");
    }
    AboutUsGet(admintoken || current_store?.token, ({ status, response }) => {
      if (response.status) {
        setloading(false);
        setLoadPage(true);
        setPrivacyList(response.aboutUs);
        setWholeMsg(response.aboutUs.termsAndConditions);
        setmsg(response.aboutUs.termsAndConditions);
      }
    });
  }, []);
  return LoadPage ? (
    <>
      <CustomNavbar
        {...{
          filter_option: false,
          type: "admin",
          menu_ctr: !Edit,
          btn1: {
            txt: Edit ? (
              <svg
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.8973 13.8618H4.59813L8.02727 17.287C9.01152 18.2713 7.53514 19.7516 6.55089 18.7673L3.42884 15.6413L1.34223 13.5547C1.14749 13.3592 1.03816 13.0945 1.03816 12.8185C1.03816 12.5425 1.14749 12.2778 1.34223 12.0823L6.55483 6.86969C6.65307 6.76892 6.77068 6.68907 6.90059 6.63494C7.03049 6.58081 7.17001 6.55353 7.31073 6.55473C7.51858 6.55527 7.72153 6.61788 7.89355 6.73454C8.06557 6.85119 8.19883 7.01656 8.27625 7.20946C8.35366 7.40235 8.37169 7.61397 8.32803 7.81718C8.28437 8.02039 8.18101 8.20592 8.03121 8.35L4.5942 11.7752H22.9525C24.3973 11.85 24.291 13.9327 22.8973 13.8618Z"
                  fill="#D0D0D1"
                />
              </svg>
            ) : (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 7.99935C4 7.64573 4.14048 7.30659 4.39052 7.05654C4.64057 6.80649 4.97971 6.66602 5.33333 6.66602H26.6667C27.0203 6.66602 27.3594 6.80649 27.6095 7.05654C27.8595 7.30659 28 7.64573 28 7.99935C28 8.35297 27.8595 8.69211 27.6095 8.94216C27.3594 9.19221 27.0203 9.33268 26.6667 9.33268H5.33333C4.97971 9.33268 4.64057 9.19221 4.39052 8.94216C4.14048 8.69211 4 8.35297 4 7.99935ZM4 23.9993C4 23.6457 4.14048 23.3066 4.39052 23.0565C4.64057 22.8065 4.97971 22.666 5.33333 22.666H12C12.3536 22.666 12.6928 22.8065 12.9428 23.0565C13.1929 23.3066 13.3333 23.6457 13.3333 23.9993C13.3333 24.353 13.1929 24.6921 12.9428 24.9422C12.6928 25.1922 12.3536 25.3327 12 25.3327H5.33333C4.97971 25.3327 4.64057 25.1922 4.39052 24.9422C4.14048 24.6921 4 24.353 4 23.9993Z"
                  fill="#D0D0D1"
                />
                <path
                  d="M4.39052 15.3905C4.14048 15.6406 4 15.9797 4 16.3333C4 16.687 4.14048 17.0261 4.39052 17.2761C4.64057 17.5262 4.97971 17.6667 5.33333 17.6667H18.6667C19.0203 17.6667 19.3594 17.5262 19.6095 17.2761C19.8595 17.0261 20 16.687 20 16.3333C20 15.9797 19.8595 15.6406 19.6095 15.3905C19.3594 15.1405 19.0203 15 18.6667 15H5.33333C4.97971 15 4.64057 15.1405 4.39052 15.3905Z"
                  fill="#D0D0D1"
                />
              </svg>
            ),
            onclick: () => {
              setEdit(false);
            },
          },
          txt: "Terms and Conditions",
          btn2: {
            txt: Edit ? (
              "Save"
            ) : (
              <svg
                width="18"
                height="18"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_251_1117)">
                  <path
                    d="M12.351 3.35917L1.3468 14.3642C1.29139 14.4198 1.2519 14.4892 1.23244 14.5652L0.0126888 19.4609C-0.00525068 19.5336 -0.00415128 19.6096 0.0158808 19.6817C0.0359129 19.7538 0.0742006 19.8196 0.127046 19.8725C0.208215 19.9534 0.318116 19.9989 0.432715 19.999C0.468068 19.999 0.503284 19.9946 0.537569 19.986L5.43327 18.7661C5.5094 18.747 5.57888 18.7075 5.63429 18.6519L16.6395 7.6476L12.351 3.35917ZM19.3657 1.85864L18.1408 0.633734C17.3221 -0.184958 15.8952 -0.184146 15.0774 0.633734L13.5769 2.13426L17.8652 6.42253L19.3657 4.92204C19.7747 4.51326 19.9999 3.96913 19.9999 3.39044C19.9999 2.81175 19.7747 2.26763 19.3657 1.85864Z"
                    fill="white"
                    fill-opacity="0.8"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_251_1117">
                    <rect width="20" height="20" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            ),
            onclick: () => {
              if (Edit) {
                const content_msg = get_editor_child();
                setloading(true);
                AboutUsUpdate(
                  {
                    ...PrivacyList,
                    termsAndConditions: content_msg,
                  },
                  admintoken,
                  ({ status, response }) => {
                    if (response.status) {
                      setloading(false);
                      setEdit(false);
                      setWholeMsg(response?.aboutUs?.termsAndConditions);
                      setmsg(response?.aboutUs?.privacyPolicy);
                      scrollToTop();
                    }
                  }
                );
              } else {
                setmsg(WholeMsg);
                setEdit(true);
              }
            },
          },
        }}
      />
      <div className="privacy_policy_container">
        {Edit ? <RTE read_only={read_only} msg={msg} setmsg={setmsg} /> : ""}
        {Edit ? "" : <RTE_Readonly read_only={true} msg={WholeMsg} />}
      </div>
      <BottomNav type={"admin"} />
    </>
  ) : (
    ""
  );
};
