import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomNavbar } from "../../../component/Navbar/CustomNavbar";
import { BottomNav } from "../../../component/Navbar/BottomNav";
import { CustSwitch } from "../../../component/Switch/CustSwitch";
import { Inputs } from "../../../component/Input/Inputs";
import {
  AdminFaqsAdd,
  AdminPlansAdd,
  AdminPlansList,
  admintoken,
  CheckToken,
  PlanCreate,
  PlanEdit,
  PlanList,
  token,
} from "../../../component/Api/api";
import { useDispatch, useSelector } from "react-redux";
import { RampLeft } from "@mui/icons-material";

export const Plans = ({ setloading }) => {
  const current_store = useSelector((state) => state.checktoken_data);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [FilterOn, setFilterOn] = useState("left");
  const [PlansLists, setPlansList] = useState([]);
  const [EditPlans, setEditPlans] = useState(false);
  const [pagevalues, setpagevalues] = useState({
    heading: "",
    description: "",
    plan_type: "",
    cost: "",
    id: "",
  });
  useEffect(() => {
    setloading(true);
    if (admintoken || current_store?.token) {
      CheckToken(admintoken || current_store?.token, ({ status, response }) => {
        if (response.status) {
          if (response?.user?.type) {
            dispatch({
              type: "setCheckToken",
              payload: {
                token: admintoken || current_store?.token,
                userdetail: response.user,
                user_type: response?.user?.type,
              },
            });
          } else {
            nav("/welcome");
          }
        } else {
          setloading(false);
          nav("/admin");
        }
      });
    } else {
      setloading(false);
      nav("/admin");
    }
    PlanList(admintoken, ({ status, response }) => {
      if (response.status) {
        setloading(false);
        setPlansList(response.plans);
      }
    });
  }, []);
  return (
    <>
      <CustomNavbar
        {...{
          filter_option: false,
          type: "admin",
          menu_ctr: false,
          btn1: {
            txt: (
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.8973 13.5435H4.59813L8.02727 16.9686C9.01152 17.9529 7.53514 19.4332 6.55089 18.449L3.42884 15.323L1.34223 13.2364C1.14749 13.0408 1.03816 12.7761 1.03816 12.5001C1.03816 12.2242 1.14749 11.9595 1.34223 11.7639L6.55483 6.55133C6.65307 6.45056 6.77068 6.37071 6.90059 6.31658C7.03049 6.26246 7.17001 6.23517 7.31073 6.23637C7.51858 6.23691 7.72153 6.29952 7.89355 6.41618C8.06557 6.53283 8.19883 6.6982 8.27625 6.8911C8.35366 7.08399 8.37169 7.29561 8.32803 7.49882C8.28437 7.70203 8.18101 7.88757 8.03121 8.03164L4.5942 11.4568H22.9525C24.3973 11.5316 24.291 13.6143 22.8973 13.5435Z"
                  fill="#D0D0D1"
                />
              </svg>
            ),
            onclick: () => {
              if (EditPlans) {
                setEditPlans(false);
              } else {
                nav("/admin/dashboard");
              }
            },
          },
          txt: "Plans",
          btn2: {
            txt: "",
            onclick: () => {},
          },
        }}
      />
      <div className="plans_container">
        {EditPlans ? (
          <>
            <div className="plans_edit_section">
              <div>
                <Inputs
                  {...{
                    inpType: "type2",
                    attr: {
                      type: "text",
                      value: pagevalues.heading,
                      event: {
                        change: ({ target }) => {
                          setpagevalues((prev) => ({
                            ...prev,
                            heading: target.value,
                          }));
                        },
                      },
                    },
                    data: {
                      title: "Heading",
                    },
                  }}
                />
              </div>
              <div>
                <Inputs
                  {...{
                    inpType: "type2",
                    attr: {
                      type: "text",
                      value: pagevalues.description,
                      id: "description",
                      event: {
                        change: ({ target }) => {
                          setpagevalues((prev) => ({
                            ...prev,
                            description: target.value,
                          }));
                        },
                      },
                    },
                    data: {
                      title: "Description",
                      type: "textarea",
                    },
                  }}
                />
              </div>
              {/* <div>
                <Inputs
                  {...{
                    inpType: "type2",
                    attr: {
                      type: "text",
                      value: pagevalues.duration,
                      event: {
                        change: ({ target }) => {
                          setpagevalues((prev) => ({
                            ...prev,
                            plan_type: target.value,
                          }));
                        },
                      },
                    },
                    data: {
                      title: "Duration",
                    },
                  }}
                />
              </div> */}
              <div>
                <Inputs
                  {...{
                    inpType: "type2",
                    attr: {
                      type: "text",
                      value: pagevalues.cost,
                      event: {
                        change: ({ target }) => {
                          setpagevalues((prev) => ({
                            ...prev,
                            cost: target.value,
                          }));
                        },
                      },
                    },
                    data: {
                      title: "Cost",
                      textarea: false,
                    },
                  }}
                />
              </div>
            </div>
            {pagevalues.id ? (
              <div
                className="plans_save"
                onClick={() => {
                  setloading(true);
                  PlanEdit(
                    {
                      heading: pagevalues.heading,
                      description: pagevalues.description,
                      price: pagevalues.cost,
                      duration: FilterOn == "left" ? "monthly" : "yearly",
                      id: pagevalues.id,
                    },
                    admintoken,
                    ({ status, response }) => {
                      if (response.status) {
                        setloading(false);
                        setPlansList((prev) => [
                          ...prev.map((el) =>
                            el._id == pagevalues?.id
                              ? {
                                  ...response.plan,
                                }
                              : el
                          ),
                        ]);
                        setEditPlans(false);
                      }
                    }
                  );
                }}
              >
                Edit
              </div>
            ) : (
              <div
                className="plans_save"
                onClick={() => {
                  setloading(true);
                  PlanCreate(
                    {
                      heading: pagevalues.heading,
                      description: pagevalues.description,
                      price: pagevalues.cost,
                      duration: FilterOn == "left" ? "monthly" : "yearly",
                    },
                    admintoken,
                    ({ status, response }) => {
                      if (status) {
                        setloading(false);
                        setPlansList((prev) => [...prev, response.plan]);
                        setEditPlans(false);
                      }
                    }
                  );
                }}
              >
                Save
              </div>
            )}
          </>
        ) : (
          <>
            <div className="plans_ctr">
              <CustSwitch
                custclass=""
                {...{
                  current: FilterOn,
                  sides: {
                    right: "Yearly",
                    left: "Monthly",
                  },
                  onevent: (is_active) => {
                    setFilterOn(is_active);
                  },
                }}
              />
            </div>
            <div className="plans_list">
              {PlansLists?.filter(
                (el) =>
                  el.duration.toLowerCase() ==
                  (FilterOn == "left" ? "monthly" : "yearly")
              ).map((el, i) => (
                <div
                  key={i}
                  className="plan_box"
                  onClick={() => {
                    setEditPlans(true);
                    setpagevalues({
                      heading: el.heading || "-",
                      description: el.description || "-",
                      plan_type: el.plan_type || "-",
                      cost: el.price || "-",
                      id: el._id,
                    });
                  }}
                >
                  {el.price > 0 ? (
                    <div className="plan_box_type">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.1247 8.65044L16.5872 15.4442C16.5567 15.5863 16.4776 15.7134 16.3635 15.8035C16.2495 15.8936 16.1075 15.9412 15.9622 15.9379H3.9872C3.84186 15.9412 3.69994 15.8936 3.58586 15.8035C3.47179 15.7134 3.3927 15.5863 3.3622 15.4442L1.8747 8.65044C1.84967 8.53564 1.8575 8.41612 1.8973 8.30557C1.93709 8.19502 2.00724 8.09793 2.0997 8.02544C2.19242 7.95268 2.30381 7.90762 2.42103 7.89546C2.53825 7.88329 2.65653 7.90452 2.7622 7.95669L6.4122 9.72544L9.4372 4.37544C9.49172 4.27925 9.57077 4.19925 9.66629 4.14358C9.76181 4.08792 9.8704 4.05859 9.98095 4.05859C10.0915 4.05859 10.2001 4.08792 10.2956 4.14358C10.3911 4.19925 10.4702 4.27925 10.5247 4.37544L13.5497 9.73169L17.2247 7.95044C17.3311 7.89402 17.4516 7.86956 17.5716 7.88C17.6916 7.89045 17.8061 7.93535 17.9011 8.0093C17.9962 8.08326 18.0679 8.18312 18.1076 8.29686C18.1472 8.41061 18.1532 8.53339 18.1247 8.65044Z"
                          fill="#FFB02E"
                        />
                      </svg>
                      {el.heading}
                    </div>
                  ) : (
                    ""
                  )}
                  {el.price == 0 ? (
                    <div className="plan_box_heading">{el?.heading}</div>
                  ) : (
                    ""
                  )}
                  {el.price ? (
                    <div className="plan_box_cost">
                      ${Number(el?.price)} /<span>{el?.duration}</span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="plan_box_desc">{el?.description}</div>
                  <div className="plan_box_svg">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.10265 13.5435H20.4019L16.9727 16.9686C15.9885 17.9529 17.4649 19.4332 18.4491 18.449L21.5712 15.323L23.6578 13.2364C23.8525 13.0408 23.9618 12.7761 23.9618 12.5001C23.9618 12.2242 23.8525 11.9595 23.6578 11.7639L18.4452 6.55133C18.3469 6.45056 18.2293 6.37071 18.0994 6.31658C17.9695 6.26246 17.83 6.23517 17.6893 6.23637C17.4814 6.23691 17.2785 6.29952 17.1064 6.41618C16.9344 6.53283 16.8012 6.6982 16.7238 6.8911C16.6463 7.08399 16.6283 7.29561 16.672 7.49882C16.7156 7.70203 16.819 7.88757 16.9688 8.03164L20.4058 11.4568H2.04753C0.602653 11.5316 0.708952 13.6143 2.10265 13.5435Z"
                        fill="#D0D0D1"
                      />
                    </svg>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <BottomNav type={"admin"} />
    </>
  );
};
