import React from "react";
import { SvgCollection } from "../../../component/Image/Image";
import { date_formatter } from "../../../utils/utils";

export const Payment = ({
  pagevalues,
  setpagevalues,
  Page,
  setPage,
  List,
  setList,
  ViewPlan,
}) => {
  const num = pagevalues?.cardholder_number;
  return (
    <>
      <div className="paymet_card_design">
        <div className="card_design_bg">
          <SvgCollection img_var={"whole_card_bg"} />
        </div>
        <div className="card_design_bg_cut">
          <SvgCollection img_var={"gradient_card"} />
        </div>
        <div className="card_design_chip">
          <SvgCollection img_var={"chip"} />
        </div>
        <div className="card_design_number">
          {num?.split("")?.map((el, i) =>
            el == " " ? (
              <div className="blank_number"></div>
            ) : (
              <div>
                <SvgCollection img_var={el?.toString()} />
              </div>
            )
          )}
        </div>
        <div className="card_extra_data">
          <div>
            <div className="card_extra_data_title">Card Holder</div>
            <div className="card_extra_data_name">
              {pagevalues?.cardholder_name}
            </div>
          </div>
          <div className="card_extra_date">
            <div className="card_extra_date_title">VALID THRU</div>
            <svg
              width="5"
              height="8"
              viewBox="0 0 5 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.566406 0.482178V7.30394L4.8026 3.89439L0.566406 0.482178Z"
                fill="white"
              />
            </svg>

            <div className="card_extra_date_num">
              {date_formatter(new Date(pagevalues?.expiry_date), "month/date")}
            </div>
          </div>
        </div>
      </div>
      <div className="payment_amounts_col">
        <div className="payment_amounts_col_title">Price</div>
        <div className="payment_amounts_col_det">
          <div>{ViewPlan?.heading}</div>
          <div>${Number(ViewPlan?.price).toFixed(2)}</div>
        </div>
        <div className="payment_amounts_col_det">
          <div>Total</div>
          <div>${Number(ViewPlan?.price).toFixed(2)}</div>
        </div>
      </div>
      <div
        className="payment_btn"
        onClick={() => {
          setPage("planslist");
        }}
      >
        Proceed to pay
      </div>
    </>
  );
};
