import axios from "axios";
import { utils } from "../../utils/utils";

export let base_url;

if (utils("testing")) {
  base_url = ``;
} else {
  base_url = `https://access-be.test-server.app/`;
}

export let token = localStorage.getItem("user_token");
export let admintoken = localStorage.getItem("user_token");

export let usertype = localStorage.getItem("userType");

const api = {
  googlelogin: "admin/google-login",
  about_us_update: "about-us/update",
  about_us_get: "about-us",
  faq_list: "faq",
  faq_create: "faq/create",
  plans_list: "plans",
  plans_create: "plans/create",
  user_send_otp: "send-otp",
  user_verify_otp: "verify-otp",
  check_token: "me",
  update_user_type: "me/update-user-type",
  image_to_link: "upload-image",
  indi_profile: "individual/update-profile",
  org_profile: "organization/update-profile",
  org_all: "organization/all",
  indi_all: "individual/all",
  chat_list: "chats/me",
  admin_chat_list: "admin-chats/me",
  chat_newmessage: "chat/new-message",
  adminchat_newmessage: "admin-chat/new-message",
  create_chat: "chat",
  admin_create_chat: "admin-chat",
  admin_all: "admin/all",
  admin_create: "admin/create",
  send_feed_back: "feedback/add",
  feed_back_list: "feedbacks",
  dashboarddata: "admin/signup-stats",
};

export const AboutUsGet = (token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.about_us_get}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AboutUsUpdate = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.about_us_update}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const FaqList = (token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.faq_list}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const FaqCreate = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.faq_create}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const FaqEdit = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}faq/${data._id}/update`,
        {
          question: data.question,
          answer: data.answer,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const FaqDelete = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}faq/${data._id}/delete`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const PlanList = (token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.plans_list}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const PlanCreate = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.plans_create}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const PlanEdit = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}plans/${data.id}/update`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const SendOtp = (pagevalues, callback) => {
  try {
    axios
      .post(`${base_url}${api.user_send_otp}`, { email: pagevalues.email }, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const VerifyOtp = (pagevalues, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.user_verify_otp}`,
        { email: pagevalues.email, otp: pagevalues.password },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CheckToken = (token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.check_token}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const UpdateUserType = (usertype, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.update_user_type}`,
        {
          type: usertype,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const ImageToLink = async (base64, callback) => {
  const formData = new FormData();
  formData.append("image", base64);
  try {
    const response = await axios
      .post(`${base_url}${api.image_to_link}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        return callback({
          status: true,
          response: res.data,
        });
      });
  } catch (error) {
    return callback({
      status: false,
      response: "or Try Again",
    });
  }
};

export const IndividualProfile = (pagevalues, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.indi_profile}`,
        {
          ...pagevalues,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const OrganizationProfile = (pagevalues, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.org_profile}`,
        {
          ...pagevalues,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const OrganizationList = (token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.org_all}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const UserIndividualList = (token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.indi_all}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const UserIndividualGetByID = (id, token, callback) => {
  try {
    axios
      .post(
        `${base_url}individual/${id}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const AdmingoogleLogin = (idToken, callback) => {
  axios
    .post(`${base_url}${api.googlelogin}`, {
      google_token: idToken,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response,
      });
    });
};

export const ChatList = (token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.chat_list}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AdminChatList = (token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.admin_chat_list}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CreateChat = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.create_chat}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AdminCreateChat = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.admin_create_chat}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const GetAdminChatMessage = (id, token, callback) => {
  try {
    axios
      .post(
        `${base_url}admin-chat/${id}/messages`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const GetChatMessage = (id, token, callback) => {
  try {
    axios
      .post(
        `${base_url}chat/${id}/messages`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const ChatNewMessage = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.chat_newmessage}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AdminChatNewMessage = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.adminchat_newmessage}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const StarMessageAPi = (id, token, callback) => {
  try {
    axios
      .post(
        `${base_url}chat/${id}/star`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AdminStarMessageAPi = (id, token, callback) => {
  try {
    axios
      .post(
        `${base_url}admin-chat/${id}/star`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const ApproveDio = (id, token, callback) => {
  try {
    axios
      .post(
        `${base_url}organization/${id}/approve-dio`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const AdminGet = (token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.admin_all}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AdminCreate = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.admin_create}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AdminDelete = (id, token, callback) => {
  try {
    axios
      .post(
        `${base_url}admin/delete/${id}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const SendFeedBack = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.send_feed_back}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AllFeedBack = (token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.feed_back_list}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AdminDashboard = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.dashboarddata}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const AdminEditUser = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${data.type}/${data.id}/update-profile`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
