import React from "react";
import { subDays, subMonths, subYears } from "date-fns";
import dayjs from "dayjs";

export const utils = (type) => {
  const data = {
    testing: false,
  };

  if (type) {
    return data[type];
  }
  return "No Data";
};

const month_arr = [
  ["Jan", "January"],
  ["Feb", "February"],
  ["Mar", "March"],
  ["Apr", "April"],
  ["May", "May"],
  ["June", "June"],
  ["July", "July"],
  ["Aug", "August"],
  ["Sept", "September"],
  ["Oct", "October"],
  ["Nov", "November"],
  ["Dec", "December"],
];
const weekday_arr = [
  ["Sun", "Sunday"],
  ["Mon", "Monday"],
  ["Tue", "Tuesday"],
  ["Wed", "Wednesday"],
  ["Thur", "Thursday"],
  ["Fri", "Friday"],
  ["Sat", "Saturday"],
];
export const date_formatter = (date, format) => {
  if (!(date instanceof Date)) {
    console.error("Invalid Date");
    return "Invalid Date";
  }

  let temp_date_data = {
    month: `${date.getMonth() + 1 < 10 ? "0" : ""}${date.getMonth() + 1}`,
    mo_short: date?.getMonth()
      ? month_arr[date?.getMonth()][0]
      : month_arr[0][0] || "",

    m_long: date?.getMonth() ? month_arr[date?.getMonth()][1] : month_arr[0][1],
    date: date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
    year_short: date.getFullYear() % 100,
    year_long: date.getFullYear(),
    weekday_short: date.getDay() ? weekday_arr[date.getDay()][0] : 0,
    weekday_long: date.getDay() ? weekday_arr[date.getDay()][1] : 0,
    hour: date.toLocaleTimeString([], {
      hour: "2-digit",
    }),
    minute: date.toLocaleTimeString([], {
      minute: "2-digit",
    }),
    seconds: date.toLocaleTimeString([], {
      second: "2-digit",
    }),
    time: date.toLocaleTimeString([]),
    hrmi_ti: date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }),
  };

  for (const el of Object.keys(temp_date_data)) {
    format = format?.replaceAll(el, temp_date_data[el]);
  }

  return format;
};

export const date_filter = (filter) => {
  const now = new Date();
  let filterDate;
  switch (filter) {
    case "Today":
      filterDate = subDays(now, 1);
      break;
    case "Last 7 days":
      filterDate = subDays(now, 7);
      break;
    case "Last 14 days":
      filterDate = subDays(now, 14);
      break;
    case "Last month":
      filterDate = subMonths(now, 1);
      break;
    case "Last 3 months":
      filterDate = subMonths(now, 3);
      break;
    case "Last 6 months":
      filterDate = subMonths(now, 6);
      break;
    case "Last 1 year":
      filterDate = subYears(now, 1);
      break;
    default:
      filterDate = "";
  }
  return filterDate;
};

export const dateFilters = {
  today: () => dayjs().startOf("day"),
  "last 7 days": () => dayjs().subtract(7, "day").startOf("day"),
  "last 14 days": () => dayjs().subtract(14, "day").startOf("day"),
  "last month": () => dayjs().subtract(1, "month").startOf("day"),
  "last 3 months": () => dayjs().subtract(3, "month").startOf("day"),
  "last 6 months": () => dayjs().subtract(6, "month").startOf("day"),
  "last 1 year": () => dayjs().subtract(1, "year").startOf("day"),
};

export const checkPlan = (data) => {
  if (data?.type === "organization") {
    if (data?.directlyImpactedOrg) {
      if (data?.dioApproval) {
        if (data?.active != undefined && !data?.active) {
          data.ongoto();
        }
      } else {
        data.ongopending();
      }
    } else {
      if (data?.active != undefined && !data?.active) {
        data.ongoto();
      }
    }
  }
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
