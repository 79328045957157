import React, { useEffect, useState } from "react";
import { CustomNavbar } from "../../../component/Navbar/CustomNavbar";
import { BottomNav } from "../../../component/Navbar/BottomNav";
import { Accordian } from "../../../component/Accordain/Accordian";
import { Inputs } from "../../../component/Input/Inputs";
import { useNavigate } from "react-router-dom";
import { CheckToken, FaqList, token } from "../../../component/Api/api";
import { useDispatch, useSelector } from "react-redux";
import { checkPlan } from "../../../utils/utils";

export const UFaqs = ({ setloading }) => {
  const current_store = useSelector((state) => state.checktoken_data);
  const token = localStorage.getItem("user_token");
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [Search, setSearch] = useState("");
  const [List, setList] = useState([]);
  useEffect(() => {
    setloading(true);
    if (current_store?.token || token) {
      CheckToken(current_store?.token || token, ({ status, response }) => {
        if (response.status) {
          if (response?.user?.type) {
            dispatch({
              type: "setCheckToken",
              payload: {
                token: current_store?.token || token,
                userdetail: response.user,
                user_type: response?.user?.type,
              },
            });
            checkPlan({
              ...response.user,
              ongoto: () => {
                nav("/checkout");
              },
              ongopending: () => {
                nav("/requestprocessing");
              },
            });
          } else {
            nav("/welcome");
          }
        } else {
          setloading(false);
          nav("/login");
        }
      });
    } else {
      setloading(false);
      nav("/login");
    }
    FaqList(current_store?.token || token, ({ status, response }) => {
      if (response.status) {
        setloading(false);
        setList(response.faqs);
      }
    });
  }, []);
  return (
    <>
      <CustomNavbar
        {...{
          filter_option: true,
          type: "",
          menu_ctr: true,
          btn1: {
            txt: (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 7.99935C4 7.64573 4.14048 7.30659 4.39052 7.05654C4.64057 6.80649 4.97971 6.66602 5.33333 6.66602H26.6667C27.0203 6.66602 27.3594 6.80649 27.6095 7.05654C27.8595 7.30659 28 7.64573 28 7.99935C28 8.35297 27.8595 8.69211 27.6095 8.94216C27.3594 9.19221 27.0203 9.33268 26.6667 9.33268H5.33333C4.97971 9.33268 4.64057 9.19221 4.39052 8.94216C4.14048 8.69211 4 8.35297 4 7.99935ZM4 23.9993C4 23.6457 4.14048 23.3066 4.39052 23.0565C4.64057 22.8065 4.97971 22.666 5.33333 22.666H12C12.3536 22.666 12.6928 22.8065 12.9428 23.0565C13.1929 23.3066 13.3333 23.6457 13.3333 23.9993C13.3333 24.353 13.1929 24.6921 12.9428 24.9422C12.6928 25.1922 12.3536 25.3327 12 25.3327H5.33333C4.97971 25.3327 4.64057 25.1922 4.39052 24.9422C4.14048 24.6921 4 24.353 4 23.9993Z"
                  fill="#D0D0D1"
                />
                <path
                  d="M4.39052 15.3905C4.14048 15.6406 4 15.9797 4 16.3333C4 16.687 4.14048 17.0261 4.39052 17.2761C4.64057 17.5262 4.97971 17.6667 5.33333 17.6667H18.6667C19.0203 17.6667 19.3594 17.5262 19.6095 17.2761C19.8595 17.0261 20 16.687 20 16.3333C20 15.9797 19.8595 15.6406 19.6095 15.3905C19.3594 15.1405 19.0203 15 18.6667 15H5.33333C4.97971 15 4.64057 15.1405 4.39052 15.3905Z"
                  fill="#D0D0D1"
                />
              </svg>
            ),
            onclick: () => {},
          },
          txt: "FAQ’s",
          btn2: {
            txt: "",
            onclick: () => {},
          },
        }}
      />
      <div className="ufaqs_container">
        <div className="ufaqs_search">
          <Inputs
            {...{
              inpType: "type1",
              attr: {
                class: "search",
                readonly: "",
                value: Search,
                id: "search",
                type: "text",
                event: {
                  change: ({ target }) => {
                    if (
                      /^[a-zA-Z0-9\s#@]+$/.test(target.value) ||
                      target.value == ""
                    ) {
                      setSearch(target.value);
                    }
                  },
                },
              },
              data: {
                title: "Search",
                prefix: (
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1_6693)">
                      <path
                        d="M17.8292 17.0207L13.1819 12.4477C14.3989 11.1255 15.1466 9.37682 15.1466 7.45261C15.146 3.33639 11.7556 0 7.57302 0C3.39042 0 0 3.33639 0 7.45261C0 11.5688 3.39042 14.9052 7.57302 14.9052C9.3802 14.9052 11.0377 14.2801 12.3397 13.241L17.005 17.832C17.2323 18.056 17.6014 18.056 17.8287 17.832C17.8827 17.7792 17.9257 17.7162 17.9551 17.6465C17.9844 17.5769 17.9996 17.5021 17.9996 17.4265C17.9997 17.3509 17.9846 17.2761 17.9554 17.2064C17.9261 17.1367 17.8832 17.0736 17.8292 17.0207ZM7.57302 13.7586C4.03408 13.7586 1.16522 10.9353 1.16522 7.45261C1.16522 3.96991 4.03408 1.14663 7.57302 1.14663C11.112 1.14663 13.9808 3.96991 13.9808 7.45261C13.9808 10.9353 11.112 13.7586 7.57302 13.7586Z"
                        fill="#D0D0D1"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_6693">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                ),
                type: "",
              },
            }}
          />
        </div>

        {List?.filter((el) =>
          Object.values(el).join(",").toLowerCase().match(Search.toLowerCase())
        ).length == 0 ? (
          <div className="ufaqs_nodata">
            <div className="p_text">Cannot find what you are looking for?</div>
            <div
              className="ufaqs__nodatabtn"
              onClick={() => {
                nav("/support");
              }}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 28 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 27.1875C6.76801 27.1873 6.54558 27.0917 6.38154 26.9218C6.21749 26.7519 6.12523 26.5215 6.125 26.2812V21.75H3.5C2.05275 21.75 0.875 20.5302 0.875 19.0312V4.53125C0.875 3.03231 2.05275 1.8125 3.5 1.8125H24.5C25.9473 1.8125 27.125 3.03231 27.125 4.53125V19.0312C27.125 20.5302 25.9473 21.75 24.5 21.75H13.8696L7.54688 26.989C7.39171 27.1177 7.19879 27.1877 7 27.1875ZM3.5 3.625C3.017 3.625 2.625 4.03191 2.625 4.53125V19.0312C2.625 19.5306 3.017 19.9375 3.5 19.9375H7C7.23206 19.9375 7.45462 20.033 7.61872 20.2029C7.78281 20.3729 7.875 20.6034 7.875 20.8438V24.3962L13.0156 20.136C13.1709 20.0074 13.3637 19.9374 13.5625 19.9375H24.5C24.983 19.9375 25.375 19.5306 25.375 19.0312V4.53125C25.375 4.03191 24.983 3.625 24.5 3.625H3.5Z"
                  fill="white"
                ></path>
              </svg>
              Contact Us
            </div>
          </div>
        ) : (
          <>
            <div className="ufaqs_title">Frequently Asked Questions (FAQs) for the ACC=SS App:</div>

            <div className="ufaqs_list">
              {List?.filter((el) =>
                Object.values(el)
                  .join(",")
                  .toLowerCase()
                  .match(Search.toLowerCase())
              )?.map((el, i) => (
                <div key={i} className="faqs_box">
                  <div>
                    <div className="faqs_box_q">{el.question}</div>
                    <div className="faqs_box_a">
                      {/* {el.answer} */}
                      <div dangerouslySetInnerHTML={{ __html: el.answer }} />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div
              className="ufaqs_btn"
              onClick={() => {
                nav("/support");
              }}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 28 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 27.1875C6.76801 27.1873 6.54558 27.0917 6.38154 26.9218C6.21749 26.7519 6.12523 26.5215 6.125 26.2812V21.75H3.5C2.05275 21.75 0.875 20.5302 0.875 19.0312V4.53125C0.875 3.03231 2.05275 1.8125 3.5 1.8125H24.5C25.9473 1.8125 27.125 3.03231 27.125 4.53125V19.0312C27.125 20.5302 25.9473 21.75 24.5 21.75H13.8696L7.54688 26.989C7.39171 27.1177 7.19879 27.1877 7 27.1875ZM3.5 3.625C3.017 3.625 2.625 4.03191 2.625 4.53125V19.0312C2.625 19.5306 3.017 19.9375 3.5 19.9375H7C7.23206 19.9375 7.45462 20.033 7.61872 20.2029C7.78281 20.3729 7.875 20.6034 7.875 20.8438V24.3962L13.0156 20.136C13.1709 20.0074 13.3637 19.9374 13.5625 19.9375H24.5C24.983 19.9375 25.375 19.5306 25.375 19.0312V4.53125C25.375 4.03191 24.983 3.625 24.5 3.625H3.5Z"
                  fill="white"
                ></path>
              </svg>
              Contact Us
            </div>
            <div className="faqs_bottom_line">
              Remember, ACC=SS is committed to breaking barriers and providing
              meaningful support to individuals with felony convictions,
              financially challenged, and veterans on their journey toward a
              brighter future.
            </div>
          </>
        )}
      </div>
      <BottomNav type={""} />
    </>
  );
};
