import React, { useState } from "react";
import { CustomNavbar } from "../../../component/Navbar/CustomNavbar";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { SvgCollection } from "../../../component/Image/Image";
import { BottomNav } from "../../../component/Navbar/BottomNav";
import { Inputs } from "../../../component/Input/Inputs";
import { AllPages } from "./AllPages";

export const Card = () => {
  const nav = useNavigate();
  const [Page, setPage] = useState("cardList");
  const [pagevalues, setpagevalues] = useState({
    cardholder_name: "",
    card_number: "",
    expiry_date: null,
    security_code: "",
    card_brand: "",
  });
  const [List, setList] = useState([
    {
      name: "Jane Doe",
      number: "*9341",
      brand: "mastercard",
      bankname: "Lorem Ipsum Bank",
      active: true,
    },
    {
      name: "Jane Doe",
      number: "*9341",
      brand: "unionpay",
      bankname: "Lorem Ipsum Bank",
      active: false,
    },
  ]);
  const [OpenModal, setOpenModal] = useState(false);
  return (
    <>
      <CustomNavbar
        {...{
          filter_option: true,
          type: "",
          menu_ctr: false,

          btn1: {
            txt: (
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.8977 13.5433H4.59844L8.02757 16.9685C9.01183 17.9528 7.53545 19.4331 6.5512 18.4488L3.42915 15.3229L1.34254 13.2362C1.1478 13.0407 1.03846 12.776 1.03846 12.5C1.03846 12.2241 1.1478 11.9593 1.34254 11.7638L6.55513 6.5512C6.65337 6.45044 6.77099 6.37059 6.90089 6.31646C7.0308 6.26233 7.17031 6.23505 7.31104 6.23624C7.51888 6.23679 7.72183 6.2994 7.89386 6.41605C8.06588 6.53271 8.19914 6.69808 8.27655 6.89098C8.35396 7.08387 8.37199 7.29549 8.32833 7.4987C8.28468 7.70191 8.18132 7.88744 8.03151 8.03152L4.5945 11.4567H22.9528C24.3977 11.5315 24.2914 13.6142 22.8977 13.5433Z"
                  fill="#D0D0D1"
                />
              </svg>
            ),
            onclick: () => {
              if (Page == "payment") {
                setOpenModal(true);
                return;
              }
              nav("/profile");
            },
          },
          txt: [
            {
              name: "Cards",
              hint: "cardList",
            },
            {
              name: "Add Cards",
              hint: "addcard",
            },
            {
              name: "Payment",
              hint: "payment",
            },
          ].filter((el) => el.hint == Page)[0]?.name,
          btn2: {
            txt: "",
            onclick: () => {},
          },
        }}
      />
      {OpenModal ? (
        <div className="warning_modal_payment">
          <div>
            <div className="warning_modal_title">
              You are just a few steps away to finish the process...
            </div>
            <div className="warning_modal_btn">
              <div
                onClick={() => {
                  setPage("cardList");
                  setOpenModal(false);
                }}
              >
                Exit
              </div>
              <div
                className="warning_modal_btn_cancel"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <AllPages
        {...{
          pagevalues,
          setpagevalues,
          Page,
          setPage,
          List,
          setList,
        }}
      />
      <BottomNav type={""} />
    </>
  );
};
