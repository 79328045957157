import React from "react";
import { CardsList } from "./CardsList";
import { AddCards } from "./AddCards";
import { Payment } from "./Payment";

export const AllPages = ({
  pagevalues,
  setpagevalues,
  Page,
  setPage,
  List,
  setList,
}) => {
  const comp = {
    cardList: (
      <CardsList
        {...{
          pagevalues,
          setpagevalues,
          Page,
          setPage,
          List,
          setList,
        }}
      />
    ),
    addcard: (
      <AddCards
        {...{
          pagevalues,
          setpagevalues,
          Page,
          setPage,
          List,
          setList,
        }}
      />
    ),
    payment: (
      <Payment
        {...{
          pagevalues,
          setpagevalues,
          Page,
          setPage,
          List,
          setList,
        }}
      />
    ),
  };
  return comp[Page];
};
