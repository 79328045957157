import React, { useEffect } from "react";
import { Inputs } from "../../../component/Input/Inputs";
import { SelectInput } from "../../../component/Input/SelectInput";
import { useNavigate } from "react-router-dom";
import { date_formatter } from "../../../utils/utils";

export const OrgProfile = ({
  Page,
  setPage,
  pagevalues,
  setpagevalues,
  EditProfile,
  formDataImage,
  setEditProfile,
  errpagevalues,
  seterrpagevalues,
}) => {
  const nav = useNavigate();
  useEffect(() => {
    setpagevalues((prev) => ({
      ...prev,
      excludeViolenceHistorydummy: prev.excludeViolenceHistory ? "Yes" : "No",
      directlyImpactedOrgdummy: prev.directlyImpactedOrg ? "Yes" : "No",
      excludeSexualOffensesdummy: prev.excludeSexualOffenses ? "Yes" : "No",
    }));
  }, []);

  return (
    <div className="profile_org_section">
      <div className="uprofile_photo">
        <div
          className="user_profile_img"
          style={{
            backgroundImage: `url(${
              pagevalues?.picture || "https://imgur.com/Yjchy3r.png"
            })`,
          }}
        ></div>
        {EditProfile ? (
          <div className="user_profile_svg">
            {[
              {
                name: "Camera",
                type: "image",
                first_color: "#B758E6",
                last_color: "#C562FB",
                multiSelect: false,
                capture: true,
                input: {
                  valids: "",
                },
                input_filter: ["image/*"],
                click: () => {},
              },
            ].map((el, i) => (
              <label key={i} className="img_label" htmlFor={el.name}>
                <input
                  type="file"
                  id={el.name}
                  capture={el.capture}
                  {...{
                    ...(el.input_filter.length
                      ? { accept: el.input_filter }
                      : {}),
                  }}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={(e) => {
                    formDataImage(e);
                  }}
                  multiple={el.multiSelect}
                />
                <div>
                  <svg
                    width="23"
                    height="19"
                    viewBox="0 0 23 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5802 10.7201C14.5802 12.6725 12.992 14.2608 11.0396 14.2608C9.08715 14.2608 7.49949 12.6725 7.49949 10.7201C7.49949 8.76768 9.08715 7.17942 11.0396 7.17942C12.992 7.17942 14.5802 8.76829 14.5802 10.7201ZM22.0166 5.69251V15.7489C22.0166 16.3927 21.7608 17.0102 21.3056 17.4655C20.8503 17.9207 20.2328 18.1765 19.589 18.1765H2.49011C1.84627 18.1765 1.2288 17.9207 0.773531 17.4655C0.318266 17.0102 0.0625 16.3927 0.0625 15.7489V5.69251C0.0625 5.04867 0.318266 4.43119 0.773531 3.97593C1.2288 3.52066 1.84627 3.2649 2.49011 3.2649H5.47608V2.42494C5.47608 1.86158 5.69987 1.32129 6.09823 0.922933C6.49659 0.524576 7.03687 0.300781 7.60024 0.300781H14.4789C15.0422 0.300781 15.5825 0.524576 15.9809 0.922933C16.3792 1.32129 16.603 1.86158 16.603 2.42494V3.26429H19.589C20.9296 3.2649 22.0166 4.35186 22.0166 5.69251ZM16.4009 10.7201C16.4009 7.76387 13.9958 5.35871 11.0396 5.35871C8.08394 5.35871 5.67878 7.76387 5.67878 10.7201C5.67878 13.6763 8.08394 16.0815 11.0396 16.0815C13.9958 16.0815 16.4009 13.6763 16.4009 10.7201Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </label>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
      {EditProfile ? (
        ""
      ) : (
        <>
          <div className="uprofile_name">{pagevalues?.name || "Xyz"}</div>
          <div className="uprofile_type">{pagevalues?.type || "Type"}</div>
        </>
      )}
      {EditProfile ? (
        <div className="uprofile_inputs_list">
          <div>
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  class: "",
                  readonly: "",
                  value: pagevalues.name,
                  id: "phone",
                  error: errpagevalues?.name,
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      const cleanedInput = target.value;
                      setpagevalues((prev) => ({
                        ...prev,
                        name: cleanedInput,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        name: !cleanedInput,
                      }));
                    },
                  },
                },
                data: {
                  title: "Name",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>

          <div>
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  class: "",
                  readonly: "",
                  value: pagevalues.phone,
                  id: "phone",
                  error: errpagevalues?.phone,
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      if (!/^\d*$/i.test(target.value)) {
                        return;
                      }
                      const cleanedInput = target.value;
                      if (cleanedInput.length > 10) {
                        return;
                      }
                      setpagevalues((prev) => ({
                        ...prev,
                        phone: cleanedInput,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        phone: cleanedInput.length != 10,
                      }));
                    },
                  },
                },
                data: {
                  title: "Phone",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  class: "",
                  readonly: "",
                  value: pagevalues.address,
                  id: "phone",
                  error: errpagevalues?.address,
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      const cleanedInput = target.value;
                      setpagevalues((prev) => ({
                        ...prev,
                        address: cleanedInput,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        address: !cleanedInput,
                      }));
                    },
                  },
                },
                data: {
                  title: "Address",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  class: "",
                  readonly: "",
                  value: pagevalues.website,
                  id: "website",
                  error: errpagevalues?.website,
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        website: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        website: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  title: "Website",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  class: "",
                  readonly: "",
                  value: pagevalues.contactPersonName,
                  id: "contactPersonName",
                  error: errpagevalues?.contactPersonName,
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        contactPersonName: target.value.replace(
                          /[^a-zA-Z0-9 ]/g,
                          ""
                        ),
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        contactPersonName: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  title: "Contact Person",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            Do you exclude people with sexual offenses?
            <div className="user_btn_list">
              {["Yes", "No"].map((el, i) => (
                <div
                  className={
                    "user_btn" +
                    (pagevalues.excludeSexualOffensesdummy == el
                      ? " active_btn"
                      : "")
                  }
                  key={i}
                  onClick={() => {
                    setpagevalues((prev) => ({
                      ...prev,
                      excludeSexualOffensesdummy: el,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      excludeSexualOffensesdummy: !el,
                    }));
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
            <div
              className={
                "user_error" +
                (errpagevalues.excludeSexualOffensesdummy ? " active" : "")
              }
            >
              Select any one
            </div>
          </div>
          <div>
            Do you exclude individuals with a history of violence?
            <div className="user_btn_list">
              {["Yes", "No"].map((el, i) => (
                <div
                  className={
                    "user_btn" +
                    (pagevalues.excludeViolenceHistorydummy == el
                      ? " active_btn"
                      : "")
                  }
                  key={i}
                  onClick={() => {
                    setpagevalues((prev) => ({
                      ...prev,
                      excludeViolenceHistorydummy: el,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      excludeViolenceHistorydummy: !el,
                    }));
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
            <div
              className={
                "user_error" +
                (errpagevalues.excludeViolenceHistorydummy ? " active" : "")
              }
            >
              Select any one
            </div>
          </div>

          <div>
            <div className="org_profile_service_offer">Services Offered</div>
            <SelectInput
              {...{
                inptype: "type1",
                attr: {
                  class: "",
                  type: "",
                  value: pagevalues.servicesOffered,
                  event: {
                    click: (value) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        servicesOffered: prev.servicesOffered.includes(value)
                          ? prev.servicesOffered?.filter((el) => el != value)
                          : [...prev.servicesOffered, value],
                      }));
                    },
                  },
                },
                data: {
                  type: "",
                  placeholder: "Ex: Food",
                  list: [
                    "Clothing",
                    "Food",
                    "Housing",
                    "Medical",
                    "Mental Health",
                    "Skills Training",
                    "Carpentry",
                  ],
                  bottomnav: true,
                },
              }}
            />
          </div>
          <div>
            <div className="org_profile_service_offer">
              Describe your organization
            </div>
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  id: "orgdescription",
                  class: "",
                  readonly: "",
                  type: "text",
                  value: pagevalues.description,
                  error: errpagevalues?.description,
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        description: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        description: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  prefix: "",
                  type: "textarea",
                },
              }}
            />
          </div>
        </div>
      ) : (
        <div className="uprofile_detail">
          <div>
            <div>Organization Name</div>
            <div>{pagevalues.name || "-"}</div>
          </div>
          <div>
            <div>Phone</div>
            <div>{pagevalues.phone || "-"}</div>
          </div>

          <div>
            <div>Email</div>
            <div>{pagevalues.email || "-"}</div>
          </div>
          <div>
            <div>Address</div>
            <div>{pagevalues.address || "-"}</div>
          </div>
          <div>
            <div>Status</div>
            <div>{pagevalues.active ? "Active" : "In-active"}</div>
          </div>

          <div>
            <div>Website</div>
            <div>{pagevalues.website || "-"}</div>
          </div>
          <div>
            <div>Contact Person</div>
            <div>{pagevalues.contactPersonName || "-"}</div>
          </div>

          <div>
            <div>Is this a directly impacted organization?</div>
            <div>{pagevalues.directlyImpactedOrg ? "Yes" : "No"}</div>
          </div>
          <div>
            <div>Do you exclude people with sexual offenses?</div>
            <div>{pagevalues.excludeSexualOffenses ? "Yes" : "No"}</div>
          </div>
          <div>
            <div>Do you exclude individuals with a history of violence?</div>
            <div>{pagevalues.excludeViolenceHistory ? "Yes" : "No"}</div>
          </div>
          <div>
            <div>Services Offered</div>
            <div className="servicesoffered_list">
              {pagevalues?.servicesOffered?.map((el, i) => (
                <div key={i}>{el}</div>
              ))}
            </div>
          </div>
          <div>
            <div>Description</div>
            <div>{pagevalues.description || "-"}</div>
          </div>
        </div>
      )}
      {EditProfile ? (
        ""
      ) : pagevalues.active ? (
        <div
          className="card_open_btn"
          onClick={() => {
            window.location.href = `https://access-be.test-server.app/subscription/${pagevalues?.id}/unsubscribe`;
          }}
        >
          Un-Subscribe
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
