import React, { useState } from "react";
import { Inputs } from "../../../component/Input/Inputs";

export const SelectOrg = ({
  Page,
  setPage,
  pagevalues,
  setpagevalues,
  EditProfile,
  setEditProfile,
  List,
  setList,
  cardvalues,
  setcardvalues,
  ListsOrg,
  setListsOrg,
  ViewSelectOrg,
  setViewSelectOrg,
}) => {
  const [Search, setSearch] = useState("");

  return (
    <div className="select_org_section">
      <div className="uselectorg_list_input">
        <Inputs
          {...{
            inpType: "type1",
            attr: {
              class: "search",
              readonly: "",
              value: Search,
              id: "search",
              type: "text",
              event: {
                change: ({ target }) => {
                  if (
                    /^[a-zA-Z0-9\s#@]+$/.test(target.value) ||
                    target.value == ""
                  ) {
                    setSearch(target.value);
                  }
                },
              },
            },
            data: {
              title: "Search",
              prefix: (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1_6693)">
                    <path
                      d="M17.8292 17.0207L13.1819 12.4477C14.3989 11.1255 15.1466 9.37682 15.1466 7.45261C15.146 3.33639 11.7556 0 7.57302 0C3.39042 0 0 3.33639 0 7.45261C0 11.5688 3.39042 14.9052 7.57302 14.9052C9.3802 14.9052 11.0377 14.2801 12.3397 13.241L17.005 17.832C17.2323 18.056 17.6014 18.056 17.8287 17.832C17.8827 17.7792 17.9257 17.7162 17.9551 17.6465C17.9844 17.5769 17.9996 17.5021 17.9996 17.4265C17.9997 17.3509 17.9846 17.2761 17.9554 17.2064C17.9261 17.1367 17.8832 17.0736 17.8292 17.0207ZM7.57302 13.7586C4.03408 13.7586 1.16522 10.9353 1.16522 7.45261C1.16522 3.96991 4.03408 1.14663 7.57302 1.14663C11.112 1.14663 13.9808 3.96991 13.9808 7.45261C13.9808 10.9353 11.112 13.7586 7.57302 13.7586Z"
                      fill="#D0D0D1"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_6693">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              ),
              type: "",
            },
          }}
        />
      </div>

      <div className="select_org_list">
        {ListsOrg?.sort((b, a) => new Date(a.createdAt) - new Date(b.createdAt))
          ?.filter((el) => el.dioApproval || !el.directlyImpactedOrg)
          ?.filter((el) =>
            Object.values(el)
              .join(",")
              .toLowerCase()
              .match(Search.toLowerCase())
          )
          .map((el, i) => (
            <div
              className="select_org"
              key={i}
              onClick={() => {
                setViewSelectOrg(el);
                setPage("selectOrgView");
              }}
            >
              <img src={el?.picture} />
              <div className="select_org_detail">
                <div className="select_org_detail_name">{el?.name}</div>
                <div className="select_org_detail_desc">{el?.description}</div>
                {/* <div className="select_orgservice_offer_list">
                Tenure - 1 year
              </div> */}
              </div>
              <div className="select_org_next">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.10265 13.5435H20.4019L16.9727 16.9686C15.9885 17.9529 17.4649 19.4332 18.4491 18.449L21.5712 15.323L23.6578 13.2364C23.8525 13.0408 23.9618 12.7761 23.9618 12.5001C23.9618 12.2242 23.8525 11.9595 23.6578 11.7639L18.4452 6.55133C18.3469 6.45056 18.2293 6.37071 18.0994 6.31658C17.9695 6.26246 17.83 6.23517 17.6893 6.23637C17.4814 6.23691 17.2785 6.29952 17.1064 6.41618C16.9344 6.53283 16.8012 6.6982 16.7238 6.8911C16.6463 7.08399 16.6283 7.29561 16.672 7.49882C16.7156 7.70203 16.819 7.88757 16.9688 8.03164L20.4058 11.4568H2.04753C0.602653 11.5316 0.708952 13.6143 2.10265 13.5435Z"
                    fill="#D0D0D1"
                  />
                </svg>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
