import React from "react";
import { RangeBox } from "../../../component/AnalyticsComp/RangeBox";

export const OrgAnalytics = ({ data: people, FilterObj }) => {
  return (
    <>
      <RangeBox
        {...{
          title: "Geography",
          type: "(Organization)",
          lastday: FilterObj?.empty[0],
          list: people?.locations?.map((el) => ({
            ...el,
            _id: el.name,
            count: el.total_org,
          })),
          total: people?.total,
        }}
      />
      <RangeBox
        {...{
          title: "Services offered",
          type: "(Organization)",
          lastday: FilterObj?.empty[0],
          list: people?.services,
          total: people?.total,
        }}
      />

      <div className="solitary_confinment">
        <div className="solitary_confinment_num">
          Directly Impacted Organizations - {people?.directlyImpactedOrgs || 0}
        </div>
        <div className="solitary_confinment_num">
          Organization Approved - {people?.diosApproved || 0}
        </div>
        <div className="solitary_confinment_num">
          Organization Pending -{" "}
          {people?.directlyImpactedOrgs - people?.diosApproved || 0}
        </div>
        <div className="solitary_confinment_num">
          Exclude Sexual Offenses - {people?.excludeSexualOffenses || 0}
        </div>
        <div className="solitary_confinment_num">
          Exclude Violent History - {people?.excludeViolentHistory || 0}
        </div>
      </div>
    </>
  );
};
