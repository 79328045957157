import React, { useEffect, useState } from "react";
import { BottomNav } from "../../../component/Navbar/BottomNav";
import { CustomNavbar } from "../../../component/Navbar/CustomNavbar";
import QRCode from "react-qr-code";
import { Inputs } from "../../../component/Input/Inputs";
import {
  AboutUsGet,
  CheckToken,
  SendFeedBack,
  token,
} from "../../../component/Api/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkPlan } from "../../../utils/utils";

export const UAboutUs = ({ setloading }) => {
  const token = localStorage.getItem("user_token");
  const current_store = useSelector((state) => state.checktoken_data);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [feed_back, setFeed_back] = useState("");
  const [pagevalues, setPagevalues] = useState({
    image: "",
    desc: "",
    title: "",
    website: "",
    facebook: "",
    twitter: "",
    instagram: "",
    linkedin: "",
  });
  useEffect(() => {
    setloading(true);
    if (current_store?.token || token) {
      CheckToken(current_store?.token || token, ({ status, response }) => {
        if (response.status) {
          if (response?.user?.type) {
            dispatch({
              type: "setCheckToken",
              payload: {
                token: current_store?.token || token,
                userdetail: response.user,
                user_type: response?.user?.type,
              },
            });
            checkPlan({
              ...response.user,
              ongoto: () => {
                nav("/checkout");
              },
              ongopending: () => {
                nav("/requestprocessing");
              },
            });
          } else {
            nav("/welcome");
          }
        } else {
          setloading(false);
          nav("/login");
        }
      });
    } else {
      setloading(false);
      nav("/login");
    }
    AboutUsGet(current_store?.token || token, ({ status, response }) => {
      if (response.status) {
        setloading(false);
        setPagevalues((prev) => ({
          ...prev,
          image: response.aboutUs.logo,
          desc: response.aboutUs.about,

          facebook: response.aboutUs.socialMediaLinks.facebook || "",
          twitter: response.aboutUs.socialMediaLinks.twitter || "",
          instagram: response.aboutUs.socialMediaLinks.instagram || "",
          linkedin: response.aboutUs.socialMediaLinks.linkedin || "",
        }));
      }
    });
  }, []);
  return (
    <>
      <CustomNavbar
        {...{
          filter_option: true,
          type: "",
          menu_ctr: true,
          btn1: {
            txt: (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 7.99935C4 7.64573 4.14048 7.30659 4.39052 7.05654C4.64057 6.80649 4.97971 6.66602 5.33333 6.66602H26.6667C27.0203 6.66602 27.3594 6.80649 27.6095 7.05654C27.8595 7.30659 28 7.64573 28 7.99935C28 8.35297 27.8595 8.69211 27.6095 8.94216C27.3594 9.19221 27.0203 9.33268 26.6667 9.33268H5.33333C4.97971 9.33268 4.64057 9.19221 4.39052 8.94216C4.14048 8.69211 4 8.35297 4 7.99935ZM4 23.9993C4 23.6457 4.14048 23.3066 4.39052 23.0565C4.64057 22.8065 4.97971 22.666 5.33333 22.666H12C12.3536 22.666 12.6928 22.8065 12.9428 23.0565C13.1929 23.3066 13.3333 23.6457 13.3333 23.9993C13.3333 24.353 13.1929 24.6921 12.9428 24.9422C12.6928 25.1922 12.3536 25.3327 12 25.3327H5.33333C4.97971 25.3327 4.64057 25.1922 4.39052 24.9422C4.14048 24.6921 4 24.353 4 23.9993Z"
                  fill="#D0D0D1"
                />
                <path
                  d="M4.39052 15.3905C4.14048 15.6406 4 15.9797 4 16.3333C4 16.687 4.14048 17.0261 4.39052 17.2761C4.64057 17.5262 4.97971 17.6667 5.33333 17.6667H18.6667C19.0203 17.6667 19.3594 17.5262 19.6095 17.2761C19.8595 17.0261 20 16.687 20 16.3333C20 15.9797 19.8595 15.6406 19.6095 15.3905C19.3594 15.1405 19.0203 15 18.6667 15H5.33333C4.97971 15 4.64057 15.1405 4.39052 15.3905Z"
                  fill="#D0D0D1"
                />
              </svg>
            ),
            onclick: () => {},
          },
          txt: "About us",
          btn2: {
            txt: "",
            onclick: () => {},
          },
        }}
      />
      <div className="uabout_container">
        <div className="uabout_title">About ACC≅SS</div>
        <div className="uabout_desc">{pagevalues?.desc}</div>
        <div className="uabout_barcode">
          <div className="uaboutus_qrcode_box">
            <QRCode
              value={window.location.origin}
              size={175}
              level="Q"
              fgColor="#fff"
              bgColor="#141218"
            />
          </div>
          <div className="uaboutus_change">Scan to share the app</div>
        </div>
        <div className="uabout_contact_to">
          For any query write us at <span>access@gmail.com</span>
        </div>
        <div className="aboutus_social_icnos">
          {pagevalues.facebook ? (
            <div
              onClick={() => {
                // window.open(pagevalues.facebook);
                window.location.href = pagevalues.facebook;
              }}
            >
              <svg
                fill="#fff"
                width="800"
                height="800"
                viewBox="-5.5 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.188 5.594h18.438c0.625 0 1.188 0.563 1.188 1.188v18.438c0 0.625-0.563 1.188-1.188 1.188h-18.438c-0.625 0-1.188-0.563-1.188-1.188v-18.438c0-0.625 0.563-1.188 1.188-1.188zM14.781 17.281h2.875l0.125-2.75h-3v-2.031c0-0.781 0.156-1.219 1.156-1.219h1.75l0.063-2.563s-0.781-0.125-1.906-0.125c-2.75 0-3.969 1.719-3.969 3.563v2.375h-2.031v2.75h2.031v7.625h2.906v-7.625z"></path>
              </svg>
            </div>
          ) : (
            ""
          )}
          {pagevalues.twitter ? (
            <div
              onClick={() => {
                // window.open(pagevalues.twitter);
                window.location.href = pagevalues.twitter;
              }}
            >
              <svg
                fill="#fff"
                width="800px"
                height="800px"
                viewBox="-4 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M24 8.531c-0.688 1-1.5 1.844-2.469 2.563 0.031 0.219 0.031 0.438 0.031 0.656 0 6.5-4.938 14-14 14-2.781 0-5.375-0.844-7.563-2.219 0.375 0.031 0.781 0.094 1.188 0.094 2.313 0 4.406-0.813 6.094-2.125-2.188-0.031-3.969-1.5-4.594-3.438 0.281 0.063 0.625 0.094 0.938 0.094 0.438 0 0.906-0.063 1.313-0.188-2.281-0.438-3.969-2.406-3.969-4.781v-0.063c0.688 0.344 1.406 0.563 2.219 0.594-1.313-0.906-2.188-2.406-2.188-4.094 0-0.906 0.25-1.75 0.656-2.5 2.438 2.969 6.063 4.969 10.156 5.156-0.063-0.344-0.125-0.75-0.125-1.125 0-2.719 2.188-4.938 4.906-4.938 1.438 0 2.719 0.625 3.625 1.594 1.125-0.219 2.156-0.656 3.094-1.219-0.344 1.156-1.125 2.156-2.125 2.75 1-0.125 1.906-0.406 2.813-0.813z"></path>
              </svg>
            </div>
          ) : (
            ""
          )}
          {pagevalues.instagram ? (
            <div
              onClick={() => {
                // window.open(pagevalues.instagram);
                window.location.href = pagevalues.instagram;
              }}
            >
              <svg
                fill="#fff"
                width="800px"
                height="800px"
                viewBox="-5.5 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M21.25 14.188v-6.469c0-1.156-0.969-2.125-2.125-2.125h-17c-1.156 0-2.125 0.969-2.125 2.125v6.469h5.156c0.813-2.219 2.969-3.813 5.469-3.813s4.656 1.594 5.469 3.813h5.156zM21.25 16.063h-4.781v0.156c0 3.219-2.625 5.844-5.844 5.844s-5.844-2.625-5.844-5.844v-0.156h-4.781v8.656c0 1.156 0.969 2.125 2.125 2.125h17c1.156 0 2.125-0.969 2.125-2.125v-8.656zM1.844 7.469h1.063v4.875h-1.063v-4.875zM3.969 7.469h1.063v4.875h-1.063v-4.875zM6.094 7.469h1.063v3.063c-0.344 0.25-0.719 0.531-1.063 0.813v-3.875zM10.625 20.219c2.219 0 3.969-1.781 3.969-4s-1.75-3.969-3.969-3.969-4 1.75-4 3.969 1.781 4 4 4zM14.344 7.469h5.031v4.875h-3.375c-0.438-0.656-1.031-1.188-1.656-1.625v-3.25z"></path>
              </svg>
            </div>
          ) : (
            ""
          )}
          {pagevalues.linkedin ? (
            <div
              onClick={() => {
                // window.open(pagevalues.linkedin);
                window.location.href = pagevalues.linkedin;
              }}
            >
              <svg
                fill="#fff"
                width="800px"
                height="800px"
                viewBox="-5.5 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 8.219v15.563c0 1.469 1.156 2.625 2.625 2.625h15.563c0.719 0 1.406-0.344 1.844-0.781 0.469-0.469 0.781-1.063 0.781-1.844v-15.563c0-1.469-1.156-2.625-2.625-2.625h-15.563c-0.781 0-1.375 0.313-1.844 0.781-0.438 0.438-0.781 1.125-0.781 1.844zM2.813 10.281c0-1 0.813-1.875 1.813-1.875 1.031 0 1.875 0.875 1.875 1.875 0 1.031-0.844 1.844-1.875 1.844-1 0-1.813-0.813-1.813-1.844zM7.844 23.125v-9.531c0-0.219 0.219-0.406 0.375-0.406h2.656c0.375 0 0.375 0.438 0.375 0.719 0.75-0.75 1.719-0.938 2.719-0.938 2.438 0 4 1.156 4 3.719v6.438c0 0.219-0.188 0.406-0.375 0.406h-2.75c-0.219 0-0.375-0.219-0.375-0.406v-5.813c0-0.969-0.281-1.5-1.375-1.5-1.375 0-1.719 0.906-1.719 2.125v5.188c0 0.219-0.219 0.406-0.438 0.406h-2.719c-0.156 0-0.375-0.219-0.375-0.406zM2.875 23.125v-9.531c0-0.219 0.219-0.406 0.375-0.406h2.719c0.25 0 0.406 0.156 0.406 0.406v9.531c0 0.219-0.188 0.406-0.406 0.406h-2.719c-0.188 0-0.375-0.219-0.375-0.406z"></path>
              </svg>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="uabout_line"></div>
        <div className="feed_back_title">Send us a feedback</div>
        <div className="feed_back_desc">
          Your input will enable us to enhance your experience.
        </div>
        <div className="feed_back_textarea">
          <Inputs
            {...{
              inpType: "type1",
              attr: {
                id: "uabout",
                class: "",
                readonly: "",
                value: feed_back,
                type: "text",
                event: {
                  change: ({ target }) => {
                    setFeed_back(target.value);
                  },
                },
              },
              data: {
                placeholder: "Text here....",
                prefix: "",
                type: "textarea",
              },
            }}
          />
        </div>
        <div
          className="submit_btn"
          onClick={() => {
            if (!feed_back) {
              return;
            }
            SendFeedBack(
              { text: feed_back },
              token || current_store?.token,
              ({ status, response }) => {
                if (response.status) {
                  setFeed_back("");
                }
              }
            );
          }}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
          >
            <g>
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM4 18.385L5.763 17H20V5H4v13.385zM11 13h2v2h-2v-2zm0-6h2v5h-2V7z"></path>
            </g>
          </svg>
          Submit
        </div>
      </div>
      <BottomNav type={""} />
    </>
  );
};
