import React, { useState } from "react";
import { CheckBox } from "../CheckBox/CheckBox";
import { useRef } from "react";
import { useEffect } from "react";

export const SelectInput = ({ inptype, attr, data }) => {
  const [DropAction, setDropAction] = useState(false);
  const [onTop, setonTop] = useState(false);
  window.addEventListener("click", () => {
    setDropAction(false);
  });
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleScroll() {
      if (dropdownRef.current) {
        const dropdownRect = dropdownRef.current.getBoundingClientRect();
        const bottomDistance = window.innerHeight - dropdownRect.bottom;
        setonTop(data?.bottomnav ? bottomDistance < 325 : bottomDistance < 230);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  switch (inptype) {
    case "type1":
      return (
        <>
          <div
            className="select_input type1"
            onClick={(e) => {
              e.stopPropagation();
            }}
            ref={dropdownRef}
          >
            <div
              className={
                "select_input_shower " +
                (data?.placeholder
                  ? ""
                  : attr.value.length
                  ? "active_pad"
                  : "") +
                (attr.error ? " select_error" : "")
              }
              onClick={() => {
                setDropAction((prev) => !prev);
              }}
            >
              {data.type == "single" ? (
                attr.value
              ) : (
                <div>
                  {(attr?.value || [])?.map((el, i) => {
                    return (
                      <>
                        <div>{el}</div>
                        {/* {attr?.value.length > i + 1 ? "," : ""}{" "} */}
                      </>
                    );
                  })}
                </div>
              )}
            </div>
            {data?.title ? (
              <div
                className={
                  "select_input_title " + (attr.value.length ? "active" : "")
                }
                onClick={() => {
                  setDropAction((prev) => !prev);
                }}
              >
                {data.title}
              </div>
            ) : (
              ""
            )}
            {data?.placeholder ? (
              <div
                className={
                  "select_input_placeholder " +
                  (attr.value.length ? "place_active" : "")
                }
                onClick={() => {
                  setDropAction((prev) => !prev);
                }}
              >
                {data.placeholder}
              </div>
            ) : (
              ""
            )}
            <div
              className={"drop_sign " + (DropAction ? "active_drop" : "")}
              onClick={() => {
                setDropAction((prev) => !prev);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {(DropAction || true) && (
              <div
                className={"select_input_dropdown "}
                style={
                  DropAction
                    ? {
                        maxHeight: 230,
                        bottom: onTop ? "100%" : "unset",
                      }
                    : {
                        bottom: onTop ? "100%" : "unset",
                      }
                }
              >
                {data?.list?.map((el, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      attr.event.click(el);
                      if (data.type == "single") {
                        setDropAction(false);
                      }
                    }}
                  >
                    <CheckBox
                      {...{
                        check:
                          data.type == "single"
                            ? attr.value == el
                            : attr.value.includes(el),
                        onclick: () => {},
                      }}
                    />
                    {el}
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      );
      break;
    case "type2":
      return (
        <>
          <div
            className={"select_input type2"}
            onClick={(e) => {
              e.stopPropagation();
            }}
            ref={dropdownRef}
          >
            <div
              className={
                "select_input_shower " +
                (data?.placeholder
                  ? ""
                  : attr.value.length
                  ? "active_pad"
                  : "") +
                (attr.error ? " select_error" : "")
              }
              onClick={() => {
                setDropAction((prev) => !prev);
              }}
            >
              {data.type == "single" ? (
                attr.value
              ) : (
                <div>
                  {(attr?.value || [])?.map((el, i) => {
                    return (
                      <>
                        <div>{el}</div>
                        {/* {attr?.value.length > i + 1 ? "," : ""}{" "} */}
                      </>
                    );
                  })}
                </div>
              )}
            </div>
            {data?.title ? (
              <div
                className={
                  "select_input_title " + (attr.value.length ? "active" : "")
                }
                onClick={() => {
                  setDropAction((prev) => !prev);
                }}
              >
                {data.title}
              </div>
            ) : (
              ""
            )}
            {data?.placeholder ? (
              <div
                className={
                  "select_input_placeholder " +
                  (attr.value.length ? "place_active" : "")
                }
                onClick={() => {
                  setDropAction((prev) => !prev);
                }}
              >
                {data.placeholder}
              </div>
            ) : (
              ""
            )}
            <div
              className={"drop_sign " + (DropAction ? "active_drop" : "")}
              onClick={() => {
                setDropAction((prev) => !prev);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {(DropAction || true) && (
              <div
                className={"select_input_dropdown "}
                style={
                  DropAction
                    ? {
                        maxHeight: 230,
                        bottom: onTop ? "100%" : "unset",
                      }
                    : {
                        bottom: onTop ? "100%" : "unset",
                      }
                }
              >
                {data?.list?.map((el, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      attr.event.click(el);
                      if (data.type == "single") {
                        setDropAction(false);
                      }
                    }}
                  >
                    <CheckBox
                      {...{
                        check:
                          data.type == "single"
                            ? attr.value == el
                            : attr.value.includes(el),
                        onclick: () => {},
                      }}
                    />
                    {el}
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      );
      break;

    default:
      break;
  }
};
