import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { date_formatter } from "../../../utils/utils";
import { Chats } from "../../../component/Message/Chats";
import {
  AdminChatList,
  admintoken,
  CheckToken,
  CreateChat,
} from "../../../component/Api/api";
import { useDispatch, useSelector } from "react-redux";
import { AdminChats } from "../../../component/Message/AdminChats";
import { Inputs } from "../../../component/Input/Inputs";
import { CustomNavbar } from "../../../component/Navbar/CustomNavbar";
import { BottomNav } from "../../../component/Navbar/BottomNav";
export const Message = () => {
  const current_store = useSelector((state) => state.checktoken_data);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [ChatData, setChatData] = useState({});
  const [OpenChat, setOpenChats] = useState(false);
  const [Search, setSearch] = useState("");
  const [MessageLists, setMessageLists] = useState([]);
  const [user_data, setuser_data] = useState({ id: "" });
  useEffect(() => {
    if (admintoken || current_store?.token) {
      CheckToken(admintoken || current_store?.token, ({ status, response }) => {
        if (response.status) {
          if (response?.user?.type) {
            dispatch({
              type: "setCheckToken",
              payload: {
                token: admintoken || current_store?.token,
                userdetail: response.user,
              },
            });
            setuser_data(response.user);
          } else {
            nav("/welcome");
          }
        } else {
          nav("/login");
        }
      });
    } else {
      nav("/login");
    }
  }, []);
  useEffect(() => {
    AdminChatList(
      admintoken || current_store?.token,
      ({ status, response }) => {
        if (response.status) {
          setMessageLists(response.chats);
        }
      }
    );
  }, [current_store]);

  return OpenChat ? (
    <>
      <AdminChats
        {...{
          user_data: current_store.userdetail,
          ChatData,
          OpenChats: OpenChat,
          onbackclick: () => {
            setOpenChats(false);
          },
        }}
      />
    </>
  ) : (
    <>
      <CustomNavbar
        {...{
          filter_option: true,
          type: "admin",
          menu_ctr: true,
          btn1: {
            txt: (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 7.99935C4 7.64573 4.14048 7.30659 4.39052 7.05654C4.64057 6.80649 4.97971 6.66602 5.33333 6.66602H26.6667C27.0203 6.66602 27.3594 6.80649 27.6095 7.05654C27.8595 7.30659 28 7.64573 28 7.99935C28 8.35297 27.8595 8.69211 27.6095 8.94216C27.3594 9.19221 27.0203 9.33268 26.6667 9.33268H5.33333C4.97971 9.33268 4.64057 9.19221 4.39052 8.94216C4.14048 8.69211 4 8.35297 4 7.99935ZM4 23.9993C4 23.6457 4.14048 23.3066 4.39052 23.0565C4.64057 22.8065 4.97971 22.666 5.33333 22.666H12C12.3536 22.666 12.6928 22.8065 12.9428 23.0565C13.1929 23.3066 13.3333 23.6457 13.3333 23.9993C13.3333 24.353 13.1929 24.6921 12.9428 24.9422C12.6928 25.1922 12.3536 25.3327 12 25.3327H5.33333C4.97971 25.3327 4.64057 25.1922 4.39052 24.9422C4.14048 24.6921 4 24.353 4 23.9993Z"
                  fill="#D0D0D1"
                />
                <path
                  d="M4.39052 15.3905C4.14048 15.6406 4 15.9797 4 16.3333C4 16.687 4.14048 17.0261 4.39052 17.2761C4.64057 17.5262 4.97971 17.6667 5.33333 17.6667H18.6667C19.0203 17.6667 19.3594 17.5262 19.6095 17.2761C19.8595 17.0261 20 16.687 20 16.3333C20 15.9797 19.8595 15.6406 19.6095 15.3905C19.3594 15.1405 19.0203 15 18.6667 15H5.33333C4.97971 15 4.64057 15.1405 4.39052 15.3905Z"
                  fill="#D0D0D1"
                />
              </svg>
            ),
            onclick: () => {},
          },
          txt: "Messages",
          btn2: {
            txt: "",
            onclick: () => {},
          },
        }}
      />
      <div className="chat_container">
        {MessageLists?.length ? (
          <>
            <div className="chat_search">
              <Inputs
                {...{
                  inpType: "type1",
                  attr: {
                    class: "search",
                    readonly: "",
                    value: Search,
                    id: "search",
                    type: "text",
                    event: {
                      change: ({ target }) => {
                        if (
                          /^[a-zA-Z0-9\s#@]+$/.test(target.value) ||
                          target.value == ""
                        ) {
                          setSearch(target.value);
                        }
                      },
                    },
                  },
                  data: {
                    title: "Search",
                    prefix: (
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1_6693)">
                          <path
                            d="M17.8292 17.0207L13.1819 12.4477C14.3989 11.1255 15.1466 9.37682 15.1466 7.45261C15.146 3.33639 11.7556 0 7.57302 0C3.39042 0 0 3.33639 0 7.45261C0 11.5688 3.39042 14.9052 7.57302 14.9052C9.3802 14.9052 11.0377 14.2801 12.3397 13.241L17.005 17.832C17.2323 18.056 17.6014 18.056 17.8287 17.832C17.8827 17.7792 17.9257 17.7162 17.9551 17.6465C17.9844 17.5769 17.9996 17.5021 17.9996 17.4265C17.9997 17.3509 17.9846 17.2761 17.9554 17.2064C17.9261 17.1367 17.8832 17.0736 17.8292 17.0207ZM7.57302 13.7586C4.03408 13.7586 1.16522 10.9353 1.16522 7.45261C1.16522 3.96991 4.03408 1.14663 7.57302 1.14663C11.112 1.14663 13.9808 3.96991 13.9808 7.45261C13.9808 10.9353 11.112 13.7586 7.57302 13.7586Z"
                            fill="#D0D0D1"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_6693">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    ),
                    type: "",
                  },
                }}
              />
            </div>

            <div className="chat_lists">
              {MessageLists?.sort(
                (b, a) => new Date(a.createdAt) - new Date(b.createdAt)
              )
                ?.filter(
                  (el) =>
                    el?.sender?.name
                      ?.toLowerCase()
                      .match(Search.toLowerCase()) ||
                    el?.receiver?.name
                      ?.toLowerCase()
                      .match(Search.toLowerCase())
                )
                .map((el, i) => (
                  <div
                    className="chat_user_box"
                    key={i}
                    onClick={() => {
                      setChatData(el);
                      setOpenChats(true);
                    }}
                  >
                    <div
                      className="chat_user_box_image"
                      style={{
                        backgroundImage: `url(${
                          el?.sender?.picture || "https://imgur.com/Yjchy3r.png"
                        })`,
                      }}
                    ></div>
                    <div className="chat_user_box_detail">
                      <div className="chat_user_box_nd">
                        <div className="chat_user_box_name">
                          {el?.sender?.name}
                        </div>
                        <div className="chat_user_box_date">
                          {date_formatter(new Date(el?.createdAt), "hrmi_ti")}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        ) : (
          <div className="no_chat_ui">
            <div className="no_chat_ui_icon">
              <svg
                width="101"
                height="101"
                viewBox="0 0 101 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M65.4444 54.6921L56.9854 63.1797C53.5552 66.622 47.5197 66.6961 44.0152 63.1797L35.5559 54.6921L5.17383 85.1728C6.30475 85.6958 7.55167 86.0079 8.87729 86.0079H92.1234C93.449 86.0079 94.6955 85.6962 95.8261 85.173L65.4444 54.6921Z"
                  fill="white"
                  fillOpacity="0.8"
                />
                <path
                  d="M92.123 14.9922H8.87695C7.55133 14.9922 6.30441 15.3043 5.17388 15.8272L37.6393 48.4003C37.6414 48.4025 37.644 48.4029 37.6462 48.405C37.6475 48.4065 37.6484 48.4082 37.6487 48.4102L48.205 59.0014C49.3263 60.1226 51.6741 60.1226 52.7954 59.0014L63.3495 48.4119C63.3495 48.4119 63.3521 48.4072 63.3542 48.405C63.3542 48.405 63.359 48.4025 63.3611 48.4003L95.8257 15.827C94.6952 15.3039 93.4487 14.9922 92.123 14.9922ZM0.944113 19.9656C0.359023 21.1488 0 22.4622 0 23.8691V77.1309C0 78.5378 0.358629 79.8511 0.943916 81.0343L31.3786 50.501L0.944113 19.9656ZM100.056 19.9653L69.6216 50.501L100.056 81.0347C100.641 79.8515 101 78.5381 101 77.1309V23.8691C101 22.4618 100.641 21.1485 100.056 19.9653Z"
                  fill="white"
                  fillOpacity="0.8"
                />
              </svg>
            </div>
            <div>No messages yet</div>
          </div>
        )}
      </div>
      <BottomNav type={"admin"} />
    </>
  );
};
