import React, { useEffect, useRef, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CreateProfileNav } from "../../../component/Navbar/CreateProfileNav";
import { useNavigate } from "react-router-dom";
import { SelectInput } from "../../../component/Input/SelectInput";
import { Inputs } from "../../../component/Input/Inputs";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  CheckToken,
  ImageToLink,
  IndividualProfile,
  token,
  UpdateUserType,
  usertype,
} from "../../../component/Api/api";
import { useDispatch, useSelector } from "react-redux";
import { date_formatter } from "../../../utils/utils";

export const IndividualForm = ({ setloading }) => {
  const current_store = useSelector((state) => state);
  const token = localStorage.getItem("user_token");
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [pagevalues, setpagevalues] = useState({
    picture: "",
    name: "",
    phone: "",
    dob: null,
    servicesSeeking: [],
    gender: "",
    city: "",
    state: "",
    zipcode: "",
    criminalBackground: "",
    paroleProbation: "",
    registeredToVote: "",
    pregnantOrIncarcerated: "",
    solitaryConfinement: "",
    veteran: "",
    lifeExperience: "",
    age: "",
    time_solitary_confinement: "",
    branchname: "",
  });
  const [errpagevalues, seterrpagevalues] = useState({
    name: false,
    phone: false,
    dob: null,
    age: false,
    gender: "",
    city: "",
    state: "",
    zipcode: "",
    criminalBackground: "",
    paroleProbation: "",
    registeredToVote: "",
    pregnantOrIncarcerated: "",
    servicesSeeking: "",
    solitaryConfinement: "",
    time_solitary_confinement: "",
    veteran: "",
    branchname: "",
    images: {},
    lifeExperience: "",
  });

  const formDataImage = (e) => {
    ImageToLink(e.target.files[0], ({ status, response }) => {
      if (status) {
        setpagevalues((prev) => ({
          ...prev,
          picture: response.url,
        }));
      }
    });
  };
  useEffect(() => {
    if (current_store?.checktoken_data?.token || token) {
      CheckToken(
        current_store?.checktoken_data?.token || token,
        ({ status, response }) => {
          if (response.status) {
            dispatch({
              type: "setCheckToken",
              payload: {
                token: current_store?.checktoken_data?.token || token,
                userdetail: response.user,
                user_type: usertype,
              },
            });
          } else {
            nav("/login");
          }
        }
      );
    } else {
      nav("/login");
    }
  }, []);
  useEffect(() => {
    if (pagevalues?.dob) {
      const birthdateString = pagevalues?.dob;
      const dob = new Date(birthdateString);
      const currentDate = new Date();
      const ageInMilliseconds = currentDate - dob;
      const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);
      setpagevalues((prev) => ({
        ...prev,
        age: Math.floor(ageInYears),
      }));
      seterrpagevalues((prev) => ({
        ...prev,
        age: !ageInYears,
      }));
    }
  }, [pagevalues?.dob]);
  return (
    <>
      <CreateProfileNav
        {...{
          title: "Create Profile",
          btn1: {
            name: (
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.8973 13.5435H4.59813L8.02727 16.9686C9.01152 17.9529 7.53514 19.4332 6.55089 18.449L3.42884 15.323L1.34223 13.2364C1.14749 13.0408 1.03816 12.7761 1.03816 12.5001C1.03816 12.2242 1.14749 11.9595 1.34223 11.7639L6.55483 6.55133C6.65307 6.45056 6.77068 6.37071 6.90059 6.31658C7.03049 6.26246 7.17001 6.23517 7.31073 6.23637C7.51858 6.23691 7.72153 6.29952 7.89355 6.41618C8.06557 6.53283 8.19883 6.6982 8.27625 6.8911C8.35366 7.08399 8.37169 7.29561 8.32803 7.49882C8.28437 7.70203 8.18101 7.88757 8.03121 8.03164L4.5942 11.4568H22.9525C24.3973 11.5316 24.291 13.6143 22.8973 13.5435Z"
                  fill="#D0D0D1"
                />
              </svg>
            ),
            clickback: () => {
              nav("/welcome");
            },
          },
          btn2: {
            name: "Next",
            clicknext: () => {
              if (
                !pagevalues.name ||
                !pagevalues.phone ||
                pagevalues.phone.length != 10 ||
                !pagevalues.age ||
                !pagevalues.gender ||
                !pagevalues.city ||
                !pagevalues.state ||
                !pagevalues.zipcode ||
                !pagevalues.lifeExperience ||
                pagevalues.servicesSeeking.length == 0 ||
                !pagevalues.criminalBackground ||
                !pagevalues.paroleProbation ||
                !pagevalues.registeredToVote ||
                !pagevalues.pregnantOrIncarcerated ||
                !pagevalues.solitaryConfinement ||
                !pagevalues.veteran
              ) {
                seterrpagevalues((prev) => ({
                  ...prev,
                  name: !pagevalues.name,
                  phone: !pagevalues.phone || pagevalues.phone.length != 10,
                  age: !pagevalues.age,
                  gender: !pagevalues.gender,
                  city: !pagevalues.city,
                  state: !pagevalues.state,
                  zipcode: !pagevalues.zipcode,
                  lifeExperience: !pagevalues.lifeExperience,
                  criminalBackground: !pagevalues.criminalBackground,
                  paroleProbation: !pagevalues.paroleProbation,
                  registeredToVote: !pagevalues.registeredToVote,
                  pregnantOrIncarcerated: !pagevalues.pregnantOrIncarcerated,
                  solitaryConfinement: !pagevalues.solitaryConfinement,
                  veteran: !pagevalues.veteran,
                  servicesSeeking: pagevalues.servicesSeeking.length == 0,
                }));
                return;
              }
              setloading(true);
              UpdateUserType(
                "individual",
                current_store?.checktoken_data?.token || token,
                ({ status, response }) => {
                  dispatch({
                    type: "setCheckToken",
                    payload: {
                      token: response.token,
                    },
                  });
                  localStorage.setItem("user_token", response.token);
                  IndividualProfile(
                    {
                      ...pagevalues,
                      dob: date_formatter(
                        new Date(pagevalues.dob),
                        "date/month/year_long"
                      ),
                      criminalBackground:
                        pagevalues.criminalBackground == "Yes" ? true : false,
                      paroleProbation:
                        pagevalues.paroleProbation == "Yes" ? true : false,
                      registeredToVote:
                        pagevalues.registeredToVote == "Yes" ? true : false,
                      pregnantOrIncarcerated:
                        pagevalues.pregnantOrIncarcerated == "Yes"
                          ? true
                          : false,
                      solitaryConfinement:
                        pagevalues.solitaryConfinement == "Yes" ? true : false,
                      veteran: pagevalues.veteran == "Yes" ? true : false,
                    },
                    response.token,
                    ({ status, response }) => {
                      if (response.status) {
                        setloading(false);
                        nav("/organization");
                      }
                    }
                  );
                }
              );
            },
          },
        }}
      />
      <div className="user_form">
        <div className="user_form_profile_photo">
          <div
            className="user_profile_img"
            style={{
              backgroundImage: `url(${
                pagevalues?.picture || "https://imgur.com/Yjchy3r.png"
              })`,
            }}
          ></div>
          <div className="user_profile_svg">
            {[
              {
                name: "Camera",
                type: "image",
                first_color: "#B758E6",
                last_color: "#C562FB",
                multiSelect: false,
                capture: false,

                input: {
                  valids: "",
                },
                input_filter: ["image/*"],
                click: () => {},
              },
            ].map((el, i) => (
              <label key={i} className="img_label" htmlFor={el.name}>
                <input
                  type="file"
                  id={el.name}
                  capture={el.capture}
                  {...{
                    ...(el.input_filter.length
                      ? { accept: el.input_filter }
                      : {}),
                  }}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={(e) => {
                    formDataImage(e);
                  }}
                  multiple={el.multiSelect}
                />
                <div>
                  <svg
                    width="23"
                    height="19"
                    viewBox="0 0 23 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5802 10.7201C14.5802 12.6725 12.992 14.2608 11.0396 14.2608C9.08715 14.2608 7.49949 12.6725 7.49949 10.7201C7.49949 8.76768 9.08715 7.17942 11.0396 7.17942C12.992 7.17942 14.5802 8.76829 14.5802 10.7201ZM22.0166 5.69251V15.7489C22.0166 16.3927 21.7608 17.0102 21.3056 17.4655C20.8503 17.9207 20.2328 18.1765 19.589 18.1765H2.49011C1.84627 18.1765 1.2288 17.9207 0.773531 17.4655C0.318266 17.0102 0.0625 16.3927 0.0625 15.7489V5.69251C0.0625 5.04867 0.318266 4.43119 0.773531 3.97593C1.2288 3.52066 1.84627 3.2649 2.49011 3.2649H5.47608V2.42494C5.47608 1.86158 5.69987 1.32129 6.09823 0.922933C6.49659 0.524576 7.03687 0.300781 7.60024 0.300781H14.4789C15.0422 0.300781 15.5825 0.524576 15.9809 0.922933C16.3792 1.32129 16.603 1.86158 16.603 2.42494V3.26429H19.589C20.9296 3.2649 22.0166 4.35186 22.0166 5.69251ZM16.4009 10.7201C16.4009 7.76387 13.9958 5.35871 11.0396 5.35871C8.08394 5.35871 5.67878 7.76387 5.67878 10.7201C5.67878 13.6763 8.08394 16.0815 11.0396 16.0815C13.9958 16.0815 16.4009 13.6763 16.4009 10.7201Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </label>
            ))}
          </div>
        </div>
        <div>
          <Inputs
            {...{
              inpType: "type1",
              attr: {
                class: "",
                readonly: "",
                type: "text",
                value: pagevalues.name,
                error: errpagevalues?.name,
                event: {
                  change: ({ target }) => {
                    setpagevalues((prev) => ({
                      ...prev,
                      name: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      name: !target.value,
                    }));
                  },
                },
              },
              data: {
                title: "Name",
                prefix: "",
              },
            }}
          />
        </div>
        <div>
          <Inputs
            {...{
              inpType: "type1",
              attr: {
                class: "",
                readonly: "",
                type: "text",
                value: pagevalues.phone,
                error: errpagevalues?.phone,
                event: {
                  change: ({ target }) => {
                    if (!/^\d*$/i.test(target.value)) {
                      return;
                    }
                    const cleanedInput = target.value;
                    if (cleanedInput.length > 10) {
                      return;
                    }
                    setpagevalues((prev) => ({
                      ...prev,
                      phone: cleanedInput,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      phone: cleanedInput.length != 10,
                    }));
                  },
                },
              },
              data: {
                title: "Phone",
                prefix: "",
              },
            }}
          />
        </div>
        <div>
          <SelectInput
            {...{
              inptype: "type1",
              attr: {
                class: "",
                type: "",
                value: pagevalues.gender,
                error: errpagevalues.gender,
                event: {
                  click: (value) => {
                    setpagevalues((prev) => ({
                      ...prev,
                      gender: value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      gender: !value,
                    }));
                  },
                },
              },
              data: {
                type: "single",
                title: "Gender",
                list: ["Male", "Female", "Other"],
                bottomnav: false,
              },
            }}
          />
        </div>
        <div>
          <ThemeProvider
            theme={createTheme({
              palette: {
                mode: "dark",
              },
            })}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="standard"
                sx={{
                  width: "100%",
                  color: "white !important",
                  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--red) !important",
                    outline: "0px !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.20) !important",
                    borderWidth: "1px !important",
                    color: "white !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.20) !important",
                    borderRadius: "8px !important",
                    borderWidth: "1px !important",
                  },
                }}
                disableFuture
                className={"cust_input"}
                value={pagevalues.dob ? dayjs(new Date(pagevalues.dob)) : null}
                onChange={(value) => {
                  setpagevalues((prev) => ({
                    ...prev,
                    dob: value,
                  }));
                }}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </ThemeProvider>
          {errpagevalues?.age ? (
            <div
              className={"user_error" + (errpagevalues.age ? " active" : "")}
            >
              Select birthdate
            </div>
          ) : (
            ""
          )}
        </div>

        <div>
          <Inputs
            {...{
              inpType: "type1",
              attr: {
                class: "",
                readonly: "",
                type: "text",
                value: pagevalues.city,
                error: errpagevalues?.city,
                event: {
                  change: ({ target }) => {
                    setpagevalues((prev) => ({
                      ...prev,
                      city: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      city: !target.value,
                    }));
                  },
                },
              },
              data: {
                title: "City",
                prefix: "",
              },
            }}
          />
        </div>
        <div>
          <Inputs
            {...{
              inpType: "type1",
              attr: {
                class: "",
                readonly: "",
                type: "text",
                value: pagevalues.state,
                error: errpagevalues?.state,
                event: {
                  change: ({ target }) => {
                    setpagevalues((prev) => ({
                      ...prev,
                      state: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      state: !target.value,
                    }));
                  },
                },
              },
              data: {
                title: "State",
                prefix: "",
              },
            }}
          />
        </div>
        <div
          style={{
            padding: 0,
          }}
        >
          <Inputs
            {...{
              inpType: "type1",
              attr: {
                class: "",
                readonly: "",
                type: "text",
                value: pagevalues.zipcode,
                error: errpagevalues?.zipcode,
                event: {
                  change: ({ target }) => {
                    if (!/^\d*$/i.test(target.value)) {
                      return;
                    }
                    setpagevalues((prev) => ({
                      ...prev,
                      zipcode: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      zipcode: !target.value || target.value.length > 6,
                    }));
                  },
                },
              },
              data: {
                title: "Zip code",
                prefix: "",
              },
            }}
          />
        </div>
        <div className="sep_user">
          Do you have a criminal background?*
          <div className="user_btn_list">
            {["Yes", "No"].map((el, i) => (
              <div
                className={
                  "user_btn" +
                  (pagevalues.criminalBackground == el ? " active_btn" : "")
                }
                key={i}
                onClick={() => {
                  setpagevalues((prev) => ({
                    ...prev,
                    criminalBackground: el,
                  }));
                  seterrpagevalues((prev) => ({
                    ...prev,
                    criminalBackground: !el,
                  }));
                }}
              >
                {el}
              </div>
            ))}
          </div>
          <div
            className={
              "user_error" + (errpagevalues.criminalBackground ? " active" : "")
            }
          >
            Select any one
          </div>
        </div>
        <div className="sep_user">
          Are you currently on parole/probation?*
          <div className="user_btn_list">
            {["Yes", "No"].map((el, i) => (
              <div
                className={
                  "user_btn" +
                  (pagevalues.paroleProbation == el ? " active_btn" : "")
                }
                key={i}
                onClick={() => {
                  setpagevalues((prev) => ({
                    ...prev,
                    paroleProbation: el,
                  }));
                  seterrpagevalues((prev) => ({
                    ...prev,
                    paroleProbation: !el,
                  }));
                }}
              >
                {el}
              </div>
            ))}
          </div>
          <div
            className={
              "user_error" + (errpagevalues.paroleProbation ? " active" : "")
            }
          >
            Select any one
          </div>
        </div>
        <div className="sep_user">
          Are you registered to vote?*
          <div className="user_btn_list">
            {["Yes", "No"].map((el, i) => (
              <div
                className={
                  "user_btn" +
                  (pagevalues.registeredToVote == el ? " active_btn" : "")
                }
                key={i}
                onClick={() => {
                  setpagevalues((prev) => ({
                    ...prev,
                    registeredToVote: el,
                  }));
                  seterrpagevalues((prev) => ({
                    ...prev,
                    registeredToVote: !el,
                  }));
                }}
              >
                {el}
              </div>
            ))}
          </div>
          <div
            className={
              "user_error" + (errpagevalues.registeredToVote ? " active" : "")
            }
          >
            Select any one
          </div>
        </div>
        <div className="sep_user">
          Have you been pregnant and incarcerated?*
          <div className="user_btn_list">
            {["Yes", "No"].map((el, i) => (
              <div
                className={
                  "user_btn" +
                  (pagevalues.pregnantOrIncarcerated == el ? " active_btn" : "")
                }
                key={i}
                onClick={() => {
                  setpagevalues((prev) => ({
                    ...prev,
                    pregnantOrIncarcerated: el,
                  }));
                  seterrpagevalues((prev) => ({
                    ...prev,
                    pregnantOrIncarcerated: !el,
                  }));
                }}
              >
                {el}
              </div>
            ))}
          </div>
          <div
            className={
              "user_error" +
              (errpagevalues.pregnantOrIncarcerated ? " active" : "")
            }
          >
            Select any one
          </div>
        </div>

        <div className="sep_user">
          Have you ever been in solitary confinement?*
          <div className="user_btn_list">
            {["Yes", "No"].map((el, i) => (
              <div
                className={
                  "user_btn" +
                  (pagevalues.solitaryConfinement == el ? " active_btn" : "")
                }
                key={i}
                onClick={() => {
                  setpagevalues((prev) => ({
                    ...prev,
                    solitaryConfinement: el,
                  }));
                  seterrpagevalues((prev) => ({
                    ...prev,
                    solitaryConfinement: !el,
                  }));
                }}
              >
                {el}
              </div>
            ))}
          </div>
          <div
            className={
              "user_error" +
              (errpagevalues.solitaryConfinement ? " active" : "")
            }
          >
            Select any one
          </div>
        </div>
        <div className="sep_user">
          Are you a veteran?*
          <div className="user_btn_list">
            {["Yes", "No"].map((el, i) => (
              <div
                className={
                  "user_btn" + (pagevalues.veteran == el ? " active_btn" : "")
                }
                key={i}
                onClick={() => {
                  setpagevalues((prev) => ({
                    ...prev,
                    veteran: el,
                  }));
                  seterrpagevalues((prev) => ({
                    ...prev,
                    veteran: !el,
                  }));
                }}
              >
                {el}
              </div>
            ))}
          </div>
          <div
            className={"user_error" + (errpagevalues.veteran ? " active" : "")}
          >
            Select any one
          </div>
        </div>
        <div className="sep_user">
          What services are you seeking?*
          <div className="user_btn_list">
            <SelectInput
              {...{
                inptype: "type1",
                attr: {
                  class: "",
                  type: "",
                  value: pagevalues.servicesSeeking,
                  error: errpagevalues.servicesSeeking,
                  event: {
                    click: (value) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        servicesSeeking: prev.servicesSeeking.includes(value)
                          ? prev.servicesSeeking.filter((el) => el != value)
                          : [...prev.servicesSeeking, value],
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        servicesSeeking: !value,
                      }));
                    },
                  },
                },
                data: {
                  type: "",
                  placeholder: "Ex: Food",
                  list: [
                    "Clothing",
                    "Food",
                    "Housing",
                    "Medical",
                    "Mental Health",
                    "Skills Training",
                    "Carpentry",
                  ],
                  bottomnav: false,
                },
              }}
            />
          </div>
        </div>
        <div className="sep_user">
          Is there anything else you want to share about your lived experience?
          <div className="user_btn_list">
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  id: "indiformdescription",
                  class: "",
                  readonly: "",
                  type: "text",
                  value: pagevalues.lifeExperience,
                  error: errpagevalues?.lifeExperience,
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        lifeExperience: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        lifeExperience: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  title: "",
                  prefix: "",
                  type: "textarea",
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
