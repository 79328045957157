import React from "react";

export const CustRange = ({ range, total }) => {
  const percentageRange = (range / total) * 100;
  return (
    <div className="range_container">
      <div
        className="range_section"
        style={{
          width: `${percentageRange}%`,
        }}
      ></div>
    </div>
  );
};
