import React, { useEffect, useState } from "react";
import { CustomNavbar } from "../../../component/Navbar/CustomNavbar";
import { BottomNav } from "../../../component/Navbar/BottomNav";
import { AboutUsGet, CheckToken, token } from "../../../component/Api/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RTE_Readonly } from "../../../component/Rich text editor/RTE_Readonly";
import { checkPlan } from "../../../utils/utils";

export const UTermsAndCondition = ({ setloading }) => {
  const current_store = useSelector((state) => state.checktoken_data);
  const token = localStorage.getItem("user_token");
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [LoadPage, setLoadPage] = useState(false);
  const [WholeMsg, setWholeMsg] = useState([]);
  useEffect(() => {
    setloading(true);
    if (current_store?.token || token) {
      CheckToken(current_store?.token || token, ({ status, response }) => {
        if (response.status) {
          if (response?.user?.type) {
            dispatch({
              type: "setCheckToken",
              payload: {
                token: current_store?.token || token,
                userdetail: response.user,
                user_type: response?.user?.type,
              },
            });
            checkPlan({
              ...response.user,
              ongoto: () => {
                nav("/checkout");
              },
              ongopending: () => {
                nav("/requestprocessing");
              },
            });
          } else {
            nav("/welcome");
          }
        } else {
          setloading(false);
          nav("/login");
        }
      });
    } else {
      setloading(false);
      nav("/login");
    }
    AboutUsGet(current_store?.token || token, ({ status, response }) => {
      if (response.status) {
        setloading(false);
        setLoadPage(true);
        setWholeMsg(response.aboutUs.termsAndConditions);
      }
    });
  }, []);
  return LoadPage ? (
    <>
      <CustomNavbar
        {...{
          filter_option: true,
          type: "",
          menu_ctr: true,
          btn1: {
            txt: (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 7.99935C4 7.64573 4.14048 7.30659 4.39052 7.05654C4.64057 6.80649 4.97971 6.66602 5.33333 6.66602H26.6667C27.0203 6.66602 27.3594 6.80649 27.6095 7.05654C27.8595 7.30659 28 7.64573 28 7.99935C28 8.35297 27.8595 8.69211 27.6095 8.94216C27.3594 9.19221 27.0203 9.33268 26.6667 9.33268H5.33333C4.97971 9.33268 4.64057 9.19221 4.39052 8.94216C4.14048 8.69211 4 8.35297 4 7.99935ZM4 23.9993C4 23.6457 4.14048 23.3066 4.39052 23.0565C4.64057 22.8065 4.97971 22.666 5.33333 22.666H12C12.3536 22.666 12.6928 22.8065 12.9428 23.0565C13.1929 23.3066 13.3333 23.6457 13.3333 23.9993C13.3333 24.353 13.1929 24.6921 12.9428 24.9422C12.6928 25.1922 12.3536 25.3327 12 25.3327H5.33333C4.97971 25.3327 4.64057 25.1922 4.39052 24.9422C4.14048 24.6921 4 24.353 4 23.9993Z"
                  fill="#D0D0D1"
                />
                <path
                  d="M4.39052 15.3905C4.14048 15.6406 4 15.9797 4 16.3333C4 16.687 4.14048 17.0261 4.39052 17.2761C4.64057 17.5262 4.97971 17.6667 5.33333 17.6667H18.6667C19.0203 17.6667 19.3594 17.5262 19.6095 17.2761C19.8595 17.0261 20 16.687 20 16.3333C20 15.9797 19.8595 15.6406 19.6095 15.3905C19.3594 15.1405 19.0203 15 18.6667 15H5.33333C4.97971 15 4.64057 15.1405 4.39052 15.3905Z"
                  fill="#D0D0D1"
                />
              </svg>
            ),
            onclick: () => {},
          },
          txt: "Terms And Condition",
          btn2: {
            txt: "",
            onclick: () => {},
          },
        }}
      />

      <div className="privacy_policy_user_container">
        <RTE_Readonly read_only={true} msg={WholeMsg} />
      </div>
      <BottomNav type={""} />
    </>
  ) : (
    ""
  );
};
